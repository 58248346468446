import { Directive, OnInit, NgZone, ElementRef, ViewChild, Output,EventEmitter, Component } from '@angular/core';
import { MapsAPILoader, MouseEvent } from '@agm/core';

@Component({
  selector: 'app-google-map-lat-long',
  templateUrl: './google-map-lat-long.component.html',
  styleUrls: ['./google-map-lat-long.component.scss'],
})
export class GoogleMapLatLongComponent implements OnInit {

  @Output() latitudeEmit:EventEmitter<number>=new EventEmitter<number>();
  @Output() longitudeEmit:EventEmitter<number>=new EventEmitter<number>();
  @Output() zoomEmit:EventEmitter<number>=new EventEmitter<number>();
  @Output() addressEmit:EventEmitter<string>=new EventEmitter<string>();
//  @Output() latlong:EventEmitter<number,number>=new EventEmitter<number,number>();
 @Output() change=new EventEmitter();
 
  latitude:number;
   longitude: number;
   zoom: number;
   address: string;
   private geoCoder;
   @ViewChild('search')
   public searchElementRef:ElementRef;// = this.elRef.nativeElement;
 
   
 
   constructor( private elRef: ElementRef,
     private mapsAPILoader: MapsAPILoader,
     private ngZone: NgZone) { }
 
 
   // Get Current Location Coordinates
   private setCurrentLocation() {
     if ('geolocation' in navigator) {
         navigator.geolocation.getCurrentPosition((position) => {
             this.latitude = position.coords.latitude;
             this.longitude = position.coords.longitude;
             this.zoom = 15;
             this.latitudeEmit.emit(this.latitude);
             this.longitudeEmit.emit(this.longitude);
             this.getAddress(this.latitude, this.longitude);
             this.change.emit(this.zoom);
             
         });
     }
 }
 
 markerDragEnd($event: MouseEvent) {
     console.log($event);
     this.latitude = $event.coords.lat;
     this.longitude = $event.coords.lng;
     this.latitudeEmit.emit(this.latitude);
     this.longitudeEmit.emit(this.longitude);
     this.getAddress(this.latitude, this.longitude);
 }
 
 getLatitude()
 {
   return this.latitude;
 }
 getLongitude()
 {
  return this.longitude;
 }
 getAddress(latitude, longitude) {
     // this.geoCoder = new google.maps.Geocoder;
 
     debugger;
     this.geoCoder.geocode({ 'location': { lat: this.latitude, lng: this.longitude } }, (results, status) => {
        
         if (status === 'OK') {
             if (results[0]) {
                 this.zoom = 12;
                 this.address = results[0].formatted_address;
                 this.addressEmit.emit(this.address);
                 this.zoomEmit.emit(this.zoom);
             } else {
                 window.alert('No results found');
             }
         } else {
            // window.alert('Geocoder failed due to: ' + status);
         }
 
     });
 }
 
   ngOnInit()
   {
    // this.searchElementRef = this.elRef.nativeElement;
     
         //load Places Autocomplete
         this.mapsAPILoader.load().then(() => {
          
           this.setCurrentLocation();

           this.geoCoder = new google.maps.Geocoder;
           console.log("searchobject",this.searchElementRef);
           
           let autocomplete = new google.maps.places.Autocomplete(this.searchElementRef.nativeElement, {
             types: ["address"]
           });
           autocomplete.addListener("place_changed", () => {
             this.ngZone.run(() => {
               //get the place result
               let place: google.maps.places.PlaceResult = autocomplete.getPlace();
     
               //verify result
               if (place.geometry === undefined || place.geometry === null) {
                 return;
               }
     
               //set latitude, longitude and zoom
               this.latitude = place.geometry.location.lat();
               this.longitude = place.geometry.location.lng();
               this.zoom = 12;
 
               this.latitudeEmit.emit(this.latitude);
               this.longitudeEmit.emit(this.longitude);
             //  this.addressEmit.emit(this.address);
               this.zoomEmit.emit(this.zoom);
             });
           });
         });
          
   }

}
