import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { HttpClientModule } from '@angular/common/http';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { MenuService } from './Services/menu.service';
//import { DataTablesModule } from 'angular-datatables';
import { AuthGuardService } from './Services/auth-guard.service';
import { AuthenticationService } from './Services/authentication.service';
import { LoginService } from './Services/Login.service';
import { HeaderPage } from './header/header.page';
import { FooterPage } from './footer/footer.page';
import { Badge } from "@ionic-native/badge/ngx";
import { Data } from './Services/data.service';
import {MenuModule} from 'primeng/menu';

// import {
//     MatStepperModule, MatInputModule, MatButtonModule, MatExpansionModule
// } from '@angular/material';
	import { EncrDecrServiceService } from './Services/encr-decr-service.service';		
import { CalculatorComponent } from './calculator/calculator.component';
	
//import { JwtHelperService,  JwtModule } from '@auth0/angular-jwt';

@NgModule({
    declarations: [AppComponent, HeaderPage, FooterPage,CalculatorComponent],
  entryComponents: [],
    imports: [BrowserModule, BrowserAnimationsModule,MenuModule,
        //MatStepperModule, MatInputModule, MatButtonModule,MatExpansionModule,
        IonicModule.forRoot(), 
		//  JwtModule.forRoot({		
    //       config: {		
    //         tokenGetter: function  tokenGetter() { 		
    //         return localStorage.getItem('jwt');		
    //         } 		
    //      }		
    //    }),
        AppRoutingModule, HttpClientModule],
  providers: [Badge,
    StatusBar,
      SplashScreen,
      MenuService,
      LoginService,
      AuthenticationService,
      AuthGuardService,
	  EncrDecrServiceService,
      Data,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
