import {TreeModule} from 'primeng/tree';
import { NgModule } from '@angular/core';
import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Routes, RouterModule } from '@angular/router';
import { IonicModule } from '@ionic/angular';
import { routes } from './mainpage.routes';
import { MainpagePage } from './mainpage.page';
import {CustomService} from '../Services/custom-service.service'
import { Data } from '../Services/data.service';
// import { MatPaginatorModule } from '@angular/material';
import { IonicStorageModule } from '@ionic/storage';
import { SharedModule } from '../shared/shared.module';
//import { AgmCoreModule } from '@agm/core';
import {TableModule} from 'primeng/Table'; 
import {PaginatorModule} from 'primeng/paginator';
import {DropdownModule} from 'primeng/dropdown';        //primeng select module
import {MultiSelectModule} from 'primeng/multiselect';  //pmg multiselect
import {AccordionModule} from 'primeng/accordion';      //pmg accordion necessary
import {TreeTableModule} from 'primeng/treetable';
import {CalendarModule} from 'primeng/calendar';
import {InputTextModule} from 'primeng/inputtext';
import {CheckboxModule} from 'primeng/checkbox';
import {RadioButtonModule} from 'primeng/radiobutton';
import {FileUploadModule} from 'primeng/fileupload';
import {ButtonModule} from 'primeng/button';
import {PanelModule} from 'primeng/panel';
import {InputNumberModule} from 'primeng/inputnumber';
import {MenubarModule} from 'primeng/menubar';
import {PanelMenuModule} from 'primeng/panelmenu';
import {OverlayPanelModule} from 'primeng/overlaypanel';
import {BreadcrumbModule} from 'primeng/breadcrumb';
import {CardModule} from 'primeng/card';
import {DialogModule} from 'primeng/dialog';
import {MenuModule} from 'primeng/menu';
import {TabViewModule} from 'primeng/tabview';
import { GoogleMapLatLongComponent } from '../google-map-lat-long/google-map-lat-long.component';
import {DataViewModule} from 'primeng/dataview';
import { ChipModule } from 'primeng/chip';
import {ContextMenuModule} from 'primeng/contextmenu';
import {VirtualScrollerModule} from 'primeng/virtualscroller';
import {BadgeModule} from 'primeng/badge';

import { ChatComponent } from '../components/ACS/chat-component/chat-component.component';
import { Chat } from '../components/ACS/Chat';
import { TokenManager } from '../components/ACS/TokenManager';
import { CommunicationClient } from '../components/ACS/CommunicationClient';
import { UserManager } from '../components/ACS/UserManager';
import { VoiceCall } from '../components/ACS/VoiceCall';
import { VideoCall } from '../components/ACS/VideoCall';
import { CallCard } from '../components/ACS/CallCard';
import {SplitButtonModule} from 'primeng/splitbutton';
import {Menu5Page} from './menu5/menu5.component';
import {Menu6Page} from './menu6/menu6.component';
import {Menu7Page} from './menu7/menu7.component';
import {Menu8Page} from './menu8/menu8.component';
import {Menu9Page} from './menu9/menu9.component';
import {Menu10Page} from './menu10/menu10.component';
import {Menu11Page} from './menu11/menu11.component';
import {Menu12Page} from './menu12/menu12.component';
import {Menu13Page} from './menu13/menu13.component';
import {Menu14Page} from './menu14/menu14.component';
import {Menu16Page} from './menu16/menu16.component';
import {Menu17Page} from './menu17/menu17.component';
import {Menu18Page} from './menu18/menu18.component';
import {Menu20Page} from './menu20/menu20.component';
import {Menu21Page} from './menu21/menu21.component';
import {Menu23Page} from './menu23/menu23.component';
import {Menu24Page} from './menu24/menu24.component';
import {Menu25Page} from './menu25/menu25.component';
import {Menu27Page} from './menu27/menu27.component';
import {Menu28Page} from './menu28/menu28.component';
import {Menu29Page} from './menu29/menu29.component';
import {Menu30Page} from './menu30/menu30.component';
import {Menu31Page} from './menu31/menu31.component';
import {Menu32Page} from './menu32/menu32.component';
import {Menu33Page} from './menu33/menu33.component';
import {Menu34Page} from './menu34/menu34.component';
import {Menu35Page} from './menu35/menu35.component';
import {Menu36Page} from './menu36/menu36.component';
import {Menu39Page} from './menu39/menu39.component';
import {Menu40Page} from './menu40/menu40.component';
import {Menu41Page} from './menu41/menu41.component';
import {Menu42Page} from './menu42/menu42.component';
import {Menu44Page} from './menu44/menu44.component';
import {Menu45Page} from './menu45/menu45.component';
import {Menu46Page} from './menu46/menu46.component';
import {Menu48Page} from './menu48/menu48.component';
import {Menu49Page} from './menu49/menu49.component';
import {Menu50Page} from './menu50/menu50.component';
import {Menu51Page} from './menu51/menu51.component';
import {Menu52Page} from './menu52/menu52.component';
import {Menu53Page} from './menu53/menu53.component';
import {Menu54Page} from './menu54/menu54.component';
import {Menu55Page} from './menu55/menu55.component';
import {Menu56Page} from './menu56/menu56.component';
import {Menu57Page} from './menu57/menu57.component';
import {Menu58Page} from './menu58/menu58.component';
import {Menu59Page} from './menu59/menu59.component';
import {Menu60Page} from './menu60/menu60.component';
import {Menu61Page} from './menu61/menu61.component';
import {Menu62Page} from './menu62/menu62.component';
import {Menu63Page} from './menu63/menu63.component';
import {Menu64Page} from './menu64/menu64.component';
import {Menu65Page} from './menu65/menu65.component';
import {Menu66Page} from './menu66/menu66.component';
import {Menu67Page} from './menu67/menu67.component';
import {Menu68Page} from './menu68/menu68.component';
import {Menu69Page} from './menu69/menu69.component';


@NgModule({
  imports: [
    CommonModule,
    FormsModule,
      IonicModule,
      RouterModule.forChild(routes),
      SharedModule,
      ReactiveFormsModule,
       MultiSelectModule,
      AccordionModule,
      DropdownModule,CardModule,
      PanelMenuModule,OverlayPanelModule,SplitButtonModule,
      MenubarModule,BreadcrumbModule,MenuModule,TabViewModule,
      TableModule,PaginatorModule,TreeTableModule,InputNumberModule,PanelModule,ButtonModule,FileUploadModule,
     RadioButtonModule,CheckboxModule,InputTextModule,CalendarModule,DialogModule,DataViewModule,TreeModule,
     BadgeModule,ChipModule,ContextMenuModule,VirtualScrollerModule,//MatMomentDateModule,
      IonicStorageModule.forRoot()
      /* AgmCoreModule.forRoot({
        apiKey: 'AIzaSyBrfK8btHhw4Xfc9EjNJjwYilg0t0s2DcI',//api key
        libraries: ['places']
       }) */

    ],
    providers: [Data,CustomService,ChatComponent,Chat,TokenManager,CommunicationClient,UserManager,VoiceCall,CallCard,VideoCall,
//,
      //{ provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } } 
],
declarations: [MainpagePage,GoogleMapLatLongComponent,ChatComponent,
  Menu5Page,Menu6Page,
  Menu7Page,Menu8Page,Menu17Page,
  Menu9Page,Menu10Page,Menu13Page,Menu14Page,Menu16Page,Menu18Page,Menu11Page,Menu12Page,Menu21Page,Menu25Page,
  Menu20Page,Menu23Page,Menu24Page,Menu27Page,Menu28Page,Menu29Page,Menu30Page,Menu31Page,
  Menu32Page,
  Menu33Page,Menu34Page,
  Menu35Page,Menu36Page,Menu39Page,Menu40Page,Menu41Page,
  Menu42Page,Menu44Page,Menu45Page,Menu46Page,Menu48Page,Menu49Page,
  Menu50Page,Menu51Page,Menu52Page,Menu53Page,Menu54Page,Menu55Page,Menu56Page,Menu57Page,Menu58Page,
  Menu59Page,Menu60Page,Menu61Page,Menu62Page,Menu63Page,Menu64Page,Menu65Page,Menu66Page,Menu67Page,Menu68Page,Menu69Page
  ],
    entryComponents: []
})
export class MainpagePageModule {}
