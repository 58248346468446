import { ElementRef, Injectable,OnInit, QueryList, ViewChildren } from '@angular/core';
import { MainPageService } from '../Services/MainPage.service';

@Injectable({
  providedIn: 'root'
})
export class Dataview implements OnInit {

    filterByValue: { label: string; value: string; }[];

    sortOptions: any[];
    sortKey: string;
    sortField: string;
    sortOrder: number;
    constructor(private mainpageservice: MainPageService) { 
    
       }

       ngOnInit(): void {
    }

    
    onSortChange(event) {//dataview sort by
        let value = event.value;

        if (value.indexOf('!') === 0) {
            this.sortOrder = -1;
            this.sortField = value.substring(1, value.length);
        }
        else {
            this.sortOrder = 1;
            this.sortField = value;
        }
    }

onImageLoad(dataViewListImage, path, rowIndex) { //Assign or load images in dataview display type
    let base64data: string | ArrayBuffer;
    this.mainpageservice.getImage("/UploadFile/" + path).subscribe(resp => {
        dataViewListImage.crossOrigin = "Anonymous";
        var reader = new FileReader();
        reader.readAsDataURL(resp);
        reader.onload = function (e) {
            base64data = reader.result;
            dataViewListImage.src = base64data.toString();
        }

    })
}

GetColumnNames(dataArray)// get list of column name for Search by(filterBy) in dataview display type
{
    if(dataArray!= undefined)
    {
        let columnNames:string;
        for (var key in dataArray)
        {
            columnNames=dataArray[key]+","+columnNames;
        }
        return columnNames;
    }
}

GetFilterOptions(ColumnName,data)//get options for filter in dataview display type
{
    var options=[];
    if(data !=undefined)
    {
            const unique = data.map(item => item[ColumnName])
            .filter((value, index, self) => self.indexOf(value) === index)
            
            for(var value in unique)
            {
                let option={};
                option["label"]=unique[value];
                option["value"]=unique[value];
                options.push(option);
            }
    }
    return options;
}

}
