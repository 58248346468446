import { Component, OnInit } from '@angular/core';
import { PopoverController } from '@ionic/angular';
import { MainPageService } from '../../Services/MainPage.service';
import { Events } from '../../Services/events.service';
import { Router } from '@angular/router';
import { NavigationExtras } from '@angular/router';
@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss'],
})
export class NotificationsComponent implements OnInit {
  Sys_Menu_ID: number =10;
  UserName: string;
  datatableparam: any = {};
  NotificationDetails: any;
  id: any;//Added
  WizardFormValue: any = {};
  Module: any;
  MyDataSourceArray: Array<any> = [];
  isExpansionDetailRow = (i: number, row: Object) => row.hasOwnProperty('detailRow');
  expandedElement: any;
  isLoadingResults = true;
  MyDataSource: any;
  isMobile:any;
  displayedColumnArray: Array<any> = [];
  displayedColumns: any[] = [];
  public show: boolean = false;
  constructor(public popoverController:PopoverController,private mainpageservice: MainPageService,
    private events: Events,
    private router: Router) { 
      events.subscribe('navigationExtras', (name) => {
        this.show = true;

        // alert('change'+name);
        let data: any;
        data = JSON.stringify(name);
        data = '{Filter:' + data + '}';
        // alert(data);
        this.mainpageservice.GetModulesWithFilter(this.Sys_Menu_ID, this.UserName, data).subscribe(data => {
            console.log("Moduleapi", data);
            this.Module = data;
            console.log(data);
            this.show = false;

        });
    });
    }

  ngOnInit() 
  {
    this.UserName = localStorage.getItem('username');
    this.mainpageservice.getNotifications(this.UserName).subscribe(filter => {
        console.log('filter', filter);
        debugger;
        this.MyDataSource = [];
        this.Module = filter;
        this.displayedColumns = ['Notifications'];
        this.Module.forEach((val) => {
                this.MyDataSource = val;
                this.MyDataSourceArray.push(this.MyDataSource);
                
            });
            
          });
          
        }
        
      
        navigate(i, LinkedMenu,Notifications,InAppID) {
         
          this.close();
          
            this.mainpageservice.AddReadInAppNotifications(this.UserName, InAppID).subscribe();

          let qm = { "rowval": i };
  
          let navigationExtras: NavigationExtras = {
              queryParams: {
                  "rowval": i
              }
  
          };
          this.events.publish('navigationExtras', qm);
          this.router.navigate(["/menu/first/tabs/" + LinkedMenu], navigationExtras);
          // this.router.navigate(["/menu/first/tabs/GotoForm"], navigationExtras);
  
      }
   

  
  
  doc()
  {
    window.open('https://material-ui.com/api/popover/','_blank');
    this.popoverController.dismiss();
  }
  close()
  {
    this.popoverController.dismiss();
  }

}
