import { Routes } from '@angular/router';
import { MainpagePage } from './mainpage.page';
import {Menu5Page} from './menu5/menu5.component';
import {Menu6Page} from './menu6/menu6.component';
import {Menu7Page} from './menu7/menu7.component';
import {Menu8Page} from './menu8/menu8.component';
import {Menu9Page} from './menu9/menu9.component';
import {Menu10Page} from './menu10/menu10.component';
import {Menu11Page} from './menu11/menu11.component';
import {Menu12Page} from './menu12/menu12.component';
import {Menu13Page} from './menu13/menu13.component';
import {Menu14Page} from './menu14/menu14.component';
import {Menu16Page} from './menu16/menu16.component';
import {Menu17Page} from './menu17/menu17.component';
import {Menu18Page} from './menu18/menu18.component';
import {Menu20Page} from './menu20/menu20.component';
import {Menu21Page} from './menu21/menu21.component';
import {Menu23Page} from './menu23/menu23.component';
import {Menu24Page} from './menu24/menu24.component';
import {Menu25Page} from './menu25/menu25.component';
import {Menu27Page} from './menu27/menu27.component';
import {Menu28Page} from './menu28/menu28.component';
import {Menu29Page} from './menu29/menu29.component';
import {Menu30Page} from './menu30/menu30.component';
import {Menu31Page} from './menu31/menu31.component';
import {Menu32Page} from './menu32/menu32.component';
import {Menu33Page} from './menu33/menu33.component';
import {Menu34Page} from './menu34/menu34.component';
import {Menu35Page} from './menu35/menu35.component';
import {Menu36Page} from './menu36/menu36.component';
import {Menu39Page} from './menu39/menu39.component';
import {Menu40Page} from './menu40/menu40.component';
import {Menu41Page} from './menu41/menu41.component';
import {Menu42Page} from './menu42/menu42.component';
import {Menu44Page} from './menu44/menu44.component';
import {Menu45Page} from './menu45/menu45.component';
import {Menu46Page} from './menu46/menu46.component';
import {Menu48Page} from './menu48/menu48.component';
import {Menu49Page} from './menu49/menu49.component';
import {Menu50Page} from './menu50/menu50.component';
import {Menu51Page} from './menu51/menu51.component';
import {Menu52Page} from './menu52/menu52.component';
import {Menu53Page} from './menu53/menu53.component';
import {Menu54Page} from './menu54/menu54.component';
import {Menu55Page} from './menu55/menu55.component';
import {Menu56Page} from './menu56/menu56.component';
import {Menu57Page} from './menu57/menu57.component';
import {Menu58Page} from './menu58/menu58.component';
import {Menu59Page} from './menu59/menu59.component';
import {Menu60Page} from './menu60/menu60.component';
import {Menu61Page} from './menu61/menu61.component';
import {Menu62Page} from './menu62/menu62.component';
import {Menu63Page} from './menu63/menu63.component';
import {Menu64Page} from './menu64/menu64.component';
import {Menu65Page} from './menu65/menu65.component';
import {Menu66Page} from './menu66/menu66.component';
import {Menu67Page} from './menu67/menu67.component';
import {Menu68Page} from './menu68/menu68.component';
import {Menu69Page} from './menu69/menu69.component';

export const routes: Routes = [
    {
        path:'',
        component: MainpagePage,
        children:[{
            path:'',
        component:Menu41Page}]
    },
{
 path: '',
 component: MainpagePage,
children: [
{
path: '5',
component: Menu5Page
},
{
path: '6',
component: Menu6Page
},
{
path: '7',
component: Menu7Page
},
{
path: '8',
component: Menu8Page
},
{
path: '9',
component: Menu9Page
},
{
path: '10',
component: Menu10Page
},
{
path: '11',
component: Menu11Page
},
{
path: '12',
component: Menu12Page
},
{
path: '13',
component: Menu13Page
},
{
path: '14',
component: Menu14Page
},
{
path: '16',
component: Menu16Page
},
{
path: '17',
component: Menu17Page
},
{
path: '18',
component: Menu18Page
},
{
path: '20',
component: Menu20Page
},
{
path: '21',
component: Menu21Page
},
{
path: '23',
component: Menu23Page
},
{
path: '24',
component: Menu24Page
},
{
path: '25',
component: Menu25Page
},
{
path: '27',
component: Menu27Page
},
{
path: '28',
component: Menu28Page
},
{
path: '29',
component: Menu29Page
},
{
path: '30',
component: Menu30Page
},
{
path: '31',
component: Menu31Page
},
{
path: '32',
component: Menu32Page
},
{
path: '33',
component: Menu33Page
},
{
path: '34',
component: Menu34Page
},
{
path: '35',
component: Menu35Page
},
{
path: '36',
component: Menu36Page
},
{
path: '39',
component: Menu39Page
},
{
path: '40',
component: Menu40Page
},
{
path: '41',
component: Menu41Page
},
{
path: '42',
component: Menu42Page
},
{
path: '44',
component: Menu44Page
},
{
path: '45',
component: Menu45Page
},
{
path: '46',
component: Menu46Page
},
{
path: '48',
component: Menu48Page
},
{
path: '49',
component: Menu49Page
},
{
path: '50',
component: Menu50Page
},
{
path: '51',
component: Menu51Page
},
{
path: '52',
component: Menu52Page
},
{
path: '53',
component: Menu53Page
},
{
path: '54',
component: Menu54Page
},
{
path: '55',
component: Menu55Page
},
{
path: '56',
component: Menu56Page
},
{
path: '57',
component: Menu57Page
},
{
    path: '58',
    component: Menu58Page
},
{
    path: '59',
    component: Menu59Page
},
{
    path: '60',
    component: Menu60Page
},
{
    path: '61',
    component: Menu61Page
},
{
    path: '62',
    component: Menu62Page
},
{
    path: '63',
    component: Menu63Page
},
{
    path: '64',
    component: Menu64Page
},
{
    path: '65',
    component: Menu65Page
},
{
    path:'66',
    component: Menu66Page
},
{
    path:'67',
    component: Menu67Page
},
{
    path:'68',
    component: Menu68Page
},
{
    path:'69',
    component: Menu69Page
},


 ]
}
];
