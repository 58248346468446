import { Component, OnInit, ViewChild, ViewChildren, AfterViewChecked, QueryList, ElementRef, HostListener } from '@angular/core';
import { MainPageService } from '../../Services/MainPage.service';
import { NavigationExtras } from '@angular/router';
import { Router, NavigationEnd, RouterEvent } from '@angular/router';
import {  filter } from 'rxjs/operators';
import { ActivatedRoute } from '@angular/router';
import { Events } from '../../Services/events.service';
import { ToastController } from '@ionic/angular';
import { saveAs } from 'file-saver';
import { NgZone } from "@angular/core"; //added
import { Storage } from '@ionic/storage';//added
import { trigger, state, transition, style, animate } from '@angular/animations';
import { CustomService } from '../../Services/custom-service.service';
import { FileSystemNode } from '../../../assets/data/file-system-node';
import { Table } from 'primeng/table';
import { NavigationStart, Event as NavigationEvent } from "@angular/router";
import { Dataview } from '../../components/dataview';
import * as $ from 'jquery';
import { XLSX$Utils } from "xlsx/types";
//import { MapsAPILoader, MouseEvent } from '@agm/core';
//import { GeocoderModule } from 'angular-geocoder';
import { environment } from '../../../environments/environment'; // added for FileDownload
import { TreeNode } from 'primeng/api';
import { Observable } from 'rxjs';
import { ComponentCanDeactivate } from '../../Services/pending-changes.guard';
import { UserManager } from 'src/app/components/ACS/UserManager';
import { AzureCommunication } from 'src/app/Services/AzureCommunication.service';


interface FileObject {
        name: string,
    FileData: Blob
}

//This variable for set value of Environment
const apiUrl = environment.apiUrl;

@Component({
    selector: 'app-menu28',
    templateUrl: './menu28.component.html',
    styleUrls: ['./menu28.component.scss'],
    animations: [
        trigger('detailExpand', [
            state('collapsed', style({ height: '0px', minHeight: '0', display: 'none' })),
            state('expanded', style({ height: '*' })),
            transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
        ]),
    ],
})
export class Menu28Page implements OnInit, ComponentCanDeactivate, AfterViewChecked {
    showFooter: boolean = true;
    displayDialog: boolean;
    displayDialogArray: any[] = [];
    thisrow: any = {};
    selectedrow: any;
    selectedValues: any;
    newrow: boolean;
    rows: any[];
    Sys_Menu_ID: number =28;

    Module: any;
    ModuleIDList: Array<any> = [];
    ModuleDetailIDList: Array<any> = [];
    hideColumnFilters: boolean = false;
    Paginators: boolean = false;
    showfilter: boolean = false;
    horizontalFilter: boolean = true;
    appliedFilters: any;
    public searchElementRef: ElementRef;
    //public minDate: any = new Date().toISOString();
    public minDate: any = "";
    public maxDate: any = "2299-12-31";
    FileName: any;//Added
    UserName: string;
    datatableparam: any = {};
    DropDown: any = {};
    PageMenuDetails: any;
    id: any;//Added
    WizardFormValue: any = {};
    isExpansionDetailRow = (i: number, row: Object) => row.hasOwnProperty('detailRow');
    expandedElementArray: Array<any> = [];
    expandedElement: any;
    isLoadingResults = true;
    displayedColumnArray: Array<any> = [];
    displayedColumns: any[] = [];
    Feedback: any;
    childColumnArray: Array<any> = [];
    childColumns: any[] = [];
    FileList: Array<File> = [];
    public show: boolean = false;
    public loading: boolean;
    // added for File Download : Sonal
    api_url: string = apiUrl;

    //-accordions-----
    step = 0;
    showContainerEmail: boolean = false;
    //--
    //EmailwithOTP
    ValidatedEmail: boolean = false;
    VerifiedEmailId: any;

    //TreeTable
    files: FileSystemNode[] = [];
    cols: any[];
    colsdisplay: any[];
    editableCols: any[];
    totalRecords: number;

    primengTableData: any[];
    scrollableCols: any;
    frozenCols: any[];
    scrollableColsArray: any[];
    frozenColsArray: any[];
    primeNgTableArray: any[];
    frozenWidthArray: any[];
    selectedColumnsArray: any[];
    treetabledataArray: any;
    filterCols: any[];
    filterColsArray: any[];
    headerLevelOne: any[];
    headerLevelOneArray: any[];
    reportTableArray: any[];
    reportTableColumnsArray: any[];

    SubmoduleDataSource: any[] = [];
    SubmoduleScrollableCols: any[] = [];
    SubmoduleFrozenCols: any[] = [];
    SubModuleFrozenWidth: any[] = [];
    SubmoduleDataSourceArray: any[] = [];
    SubmoduleScrollableColsArray: any[] = [];
    SubmoduleFrozenColsArray: any[] = [];
    SubModuleFrozenWidthArray: any[] = [];
    displayDialogModule: any[] = [];
    displayDialogSubmodule: any[] = [];


    frozenColsSubmoduleArray: any[] = [];
    frozenWidthSubModuleArray: any = [];
    displayedSubmoduleColumnArray: any[] = [];///////////Ruchita
    displayedSubmoduleColumns: any[] = [];///////////Ruchita

    treeModuleData: TreeNode[];

    timelineData: any;
    timelineTableArray: any;
    timelineTableColumnsArray: any;

    // commented by Karan on 18-05-21 @ViewChild('slides') slides: IonSlides; // added for wizard slides
    @ViewChildren('LatLong') latlong: QueryList<ElementRef>;
    lat: number;
    long: number;
    lat_long: string;

    @ViewChild('dt', { static: false }) primengTable: Table;
    ModuleDataWithRule: any;
    routerEventSubscription: any;
    rowval: string;

    Locale: string;
    Currency: string;
    DateFormat: string;
    TimeZone: string;
    hourFormat: number;
    dateTimeFormat: string;

    disable: boolean = false;
    ModuleDataWithRuleArray: any[];
    filterSplitButtonOptions = [
        {
            label: 'Horizontal',
            //icon: 'pi pi-caret-left', 
            command: () => {
                this.horizontalFilter = true; this.showfilter = !this.showfilter;
            }
        },
        {
            label: 'Vertical',
            //icon: 'pi pi-caret-down', 
            command: () => {
                this.horizontalFilter = false; this.showfilter = !this.showfilter;
            }
        }
    ];
    tableSecondaryOptions = [
        {
            label: 'Pagination',
            icon: 'pi pi-align-justify',
            command: () => {
                this.Paginators = !this.Paginators
            }
        },
    ];

    haveChangesOnPage: any;
    temptableArray: any;
    rowGroupMetadata: {};

    constructor(private mainpageservice: MainPageService,
        private customService: CustomService,
        private router: Router,
        private toastController: ToastController,//Added
        private activateRouter: ActivatedRoute,
        private zone: NgZone,//Added
        private events: Events,
        private storage: Storage,//Added
        private Dataview: Dataview,
        private ACSUser: UserManager,
        private ACS: AzureCommunication,
        // private mapsAPILoader: MapsAPILoader,
        private ngZone: NgZone
    ) {
        events.subscribe('navigationExtras', (name) => {
            this.show = true;

            // alert('change'+name);
            let data: any;
            data = JSON.stringify(name);
            data = '{Filter:' + data + '}';
            // alert(data);
            this.GetModulewithFilter(name);
        });
    }

    exportExcel(data) {
        var xlsx = require('xlsx');
        const worksheet = xlsx.utils.json_to_sheet(data);
        const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
        const excelBuffer: any = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
        this.saveAsExcelFile(excelBuffer, "export");
    }

    saveAsExcelFile(buffer: any, fileName: string): void {
        var FileSaver = require('file-saver');
        let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
        let EXCEL_EXTENSION = '.xlsx';
        const data: Blob = new Blob([buffer], {
            type: EXCEL_TYPE
        });
        FileSaver.saveAs(data, fileName + new Date().getTime() + EXCEL_EXTENSION);
    }
    //added Sonal - Menu Display Type : Wizard 
    Next(ID: any, wizardFormdata: any) {
        debugger;
        this.WizardFormValue = Object.assign(this.WizardFormValue, wizardFormdata);
        // commented by Karan on 18-05-21 this.slides.lockSwipes(false);
        // commented by Karan on 18-05-21 this.slides.slideNext();
        // commented by Karan on 18-05-21 this.slides.lockSwipes(true);
    }

    Prev() {
        // commented by Karan on 18-05-21 this.slides.lockSwipes(false);
        // commented by Karan on 18-05-21 this.slides.slidePrev();
        // commented by Karan on 18-05-21 this.slides.lockSwipes(true);
    }
    ionViewDidEnter() {
        //this.routerEventSubscription = this.router.events.pipe(filter((event: RouterEvent) => event instanceof NavigationEnd)
        //).subscribe(() => {//refresh module on reload/navigation like back or submenu click
        //    this.getPageMenuDetails();
        //});//uncomment this if component should reload on every routing

        this.routerEventSubscription = this.router.events
            .pipe(
                // The "events" stream contains all the navigation events. For this demo,
                // though, we only care about the NavigationStart event as it contains
                // information about what initiated the navigation sequence.
                filter(
                    (event: NavigationEvent) => {

                        return (event instanceof NavigationStart);

                    }
                )
            )
            .subscribe(
                (event: NavigationStart) => {
                    if (event.restoredState) {//trigger on back button
                        //let filter = { "rowval": this.rowval };
                        //this.GetModulewithFilter(filter);
                        this.getPageMenuDetails();
                    }
                }
            )

    }

    public latitude(event: any, fieldName) {////////////returns location latitude 

        const found = this.latlong.toArray().find(element => element.nativeElement.id == fieldName);
        found.nativeElement.value = event;
        found.nativeElement.dispatchEvent(new Event('input'));

    }
    public longitude(event: any, fieldName) {///////////////returns location longitude
        const found = this.latlong.toArray().find(element => element.nativeElement.id == fieldName);
        found.nativeElement.value = event;
        found.nativeElement.dispatchEvent(new Event('input'));

    }

    public latitudeLongitude(event: any, fieldName) {////////////////returns latitude and longitude

        const found = this.latlong.toArray().find(element => element.nativeElement.id == fieldName);
        found.nativeElement.value = event;
        debugger;
        found.nativeElement.dispatchEvent(new Event('input'));
    }

    public getMatDate(date) {
        var date1 = new Date(date);
        return date1;
    }

    resizeColumn(event, index: number) {
        let width: number;
        width = parseInt(this.frozenWidthArray[index].substring(0, this.frozenWidthArray[index].indexOf("px")));
        width = width + event.delta;
        this.frozenWidthArray[index] = width + "px";
    }

    formatDropdownValue(value: any) {
        if (value == null || value == undefined)
            return "";
        return value.Text || value;
    }
    assignDDselectedVal(fromvalue) {
        let ddValue: any;
        if (fromvalue.value == null) {
            ddValue = null;
        }
        else {
            ddValue = fromvalue.value.Value;
        }
        return ddValue;
    }
    getDefaultValue(value1, value2) {
        let result: any;
        if (value1 != null || value1 != -1 || value2 != null || value2 != -1) {
            value2.forEach(element => {
                if (element.Value == value1) {
                    result = element;
                }
            });
            return result;
        }
        return null;

    }
    getDefaultText(value1, value2) {
        let result: any;
        if (value1 != null || value1 != -1 || value2 != null || value2 != -1) {
            value2.forEach(element => {
                if (element.Value == value1 || element.Text == value1) {
                    result = element.Text;
                }
            });
            return result;
        }
        return null;
    }

    getMultiDropdownText(selectedValues, valueList) {

        let selected: any[] = [];
        if (selectedValues === undefined) {
            return null;
        }
        selected = selectedValues.split(",");
        var selectedText = "";
        selected.forEach(value => {
            var text = valueList.find(v => v.Value == value).Text;
            selectedText = text + ", " + selectedText;
        });
        return selectedText.substring(0, selectedText.length - 1);//returns comma separated values
    }


    isNumber(value: any) {
        if (value) {
            return isNaN(value);
        }
    }
    removePercentSign(value) {
        if (value) {
            return value.replace('%', '');
        }
    }
    isPositiveNumber(value: any) {
        if (value) {
            value = value.replace('%', '');
            return Math.sign(value);
        }
    }

    public calculatePrimengTotal(col: string, primengdata) {
        let columnName = col;
        let footerTotal: Array<any> = [];

        let data = [];
        let mymodule;
        if (primengdata.hasFilter()) {
            mymodule = primengdata.filteredValue;
        }
        else {
            mymodule = primengdata.value;
        }

        var resultArray = Object.keys(mymodule).map(function (index) {
            let data = mymodule[index];
            return data;
        });
        data = resultArray;
        footerTotal[columnName] = data.map(t => t[columnName]).reduce((acc, value) => acc + (value / 1), 0)
        if (isNaN(footerTotal[columnName]) || footerTotal[columnName] == 0) {
            footerTotal[columnName] = "";
        }

        return footerTotal[columnName];
    }

    public onSubmoduleSubmit(form: any, dt, ID: any, LinkedMenu: any, moduleIndex: number): void {
        var submodules = this.Module[0].moduleList[moduleIndex].submodule;
        submodules.forEach(submodule => {
            var dateColumns = [];
            var checkboxColumns = [];
            dateColumns = submodule.subModuleDetails.filter(md => md.DataType == "date");
            checkboxColumns = submodule.subModuleDetails.filter(md => md.InputControls == "Checkbox");

            if (submodule.DisplayType == "Form") {
                dateColumns.forEach(column => {
                    if (form[column.ColumnName] != null) {
                        let adate = new Date(form[column.ColumnName]);
                        var ayear = adate.getFullYear();
                        var amonth: any = adate.getMonth() + 1;
                        var adt: any = adate.getDate();
                        if (adt < 10) { adt = '0' + adt; }
                        if (amonth < 10) { amonth = '0' + amonth; }
                        form[column.ColumnName] = ayear + '-' + amonth + '-' + adt;
                    }
                })
                checkboxColumns.forEach(column => {
                    if (form[column.ColumnName] == null || form[column.ColumnName] == "") {
                        form[column.ColumnName] = false;
                    }
                });

            }
            else if (submodule.DisplayType == "" || submodule.DisplayType == "PrimeUnfrozenTable") {
                dt.forEach(table => {

                table.map(d => {
                    dateColumns.forEach(column => {
                        if (d[column.ColumnName] != null) {
                            let adate = new Date(d[column.ColumnName]);
                            var ayear = adate.getFullYear();
                            var amonth: any = adate.getMonth() + 1;
                            var adt: any = adate.getDate();
                            if (adt < 10) { adt = '0' + adt; }
                            if (amonth < 10) { amonth = '0' + amonth; }
                            d[column.ColumnName] = ayear + '-' + amonth + '-' + adt;
                        }
                    })
                    checkboxColumns.forEach(column => {
                        if (d[column.ColumnName] == null || d[column.ColumnName] == "") {
                            d[column.ColumnName] == false;
                        }
                    })
                });
                })

            }
        });


        let filter1 = localStorage.getItem('navigationExtras');
        this.routerEventSubscription = this.router.events.pipe(filter((event: RouterEvent) => event instanceof NavigationEnd)
        ).subscribe(() => {//refresh module on reload/navigation like back or submenu click
            this.GetModulewithFilter(filter1);
        });

        this.UserName = localStorage.getItem('username');
        this.show = true;

        this.mainpageservice.SaveSubmodules(ID, form, dt, this.UserName).subscribe(resp => {
            console.log(JSON.stringify(resp["Message"]));
            let toast = this.toastController.create({
                message: resp["Message"],
                duration: 3000,
                position: 'bottom',
                //closeButtonText: 'Ok',

            });
            this.GetModulewithFilter(filter1);
            if (LinkedMenu != 0 && LinkedMenu != '' && LinkedMenu != null) {
                this.navigateOnFormSubmit(LinkedMenu);
            }

            toast.then(toast => toast.present());
            this.show = false;

        });
    }

    loadNodes(event) {
        this.loading = true;
        this.GetPaginatedTreeTable(event);

    }

    calculateRowWiseTotalPrimeNG(rowData, dtIndex) {

        var columnsForSum = this.Module[0].moduleList[dtIndex].ColumnsForSum;
        var ShowSumIn = this.Module[0].moduleList[dtIndex].GetSumIn;
        let rowWiseTotal: number = 0;
        if (columnsForSum) {
            columnsForSum.forEach(element => {
                rowWiseTotal = rowWiseTotal + parseInt(rowData[element]);
            });
        }
        rowData[ShowSumIn] = rowWiseTotal;
    }

    calculateRowWiseTotalSubmodule(rowData, moduleIndex, subModuleIndex) {
        debugger;
        var columnsForSum = this.Module[0].moduleList[moduleIndex].submodule[subModuleIndex].ColumnsForSum;
        var ShowSumIn = this.Module[0].moduleList[moduleIndex].submodule[subModuleIndex].GetSumIn;
        let rowWiseTotal: number = 0;
        if (columnsForSum) {
            columnsForSum.forEach(element => {
                if (!isNaN(rowData[element]))
                    rowWiseTotal = rowWiseTotal + parseInt(rowData[element]);
            });
        }
        rowData[ShowSumIn] = rowWiseTotal;
    }



    GetPropertyRule(pkColumn, pkColumnValue, column, moduleIndex?: number) {
        if (moduleIndex == null) moduleIndex = 0;
        if (pkColumn == null || pkColumn == "" || pkColumn == undefined)
            return "";
        let dataWithRules = this.ModuleDataWithRuleArray[moduleIndex];
        let dataArray = Object.keys(dataWithRules).map(function (index) {//convert Object to array
            let data = dataWithRules[index];
            return data;
        });

        let propertyList: string;
        propertyList = dataArray.find(d => d[pkColumn].Value == pkColumnValue)[column].PropertyList;
        return propertyList;
    }

    GetRuleLink(pkColumn, pkColumnValue, column) {//navigate to menu when rule is applied
        if (pkColumn == null || pkColumn == "" || pkColumn == undefined)
            this.navigate(pkColumnValue, this.Sys_Menu_ID);
        else {
            let dataWithRules = this.ModuleDataWithRuleArray[0];
            let dataArray = Object.keys(dataWithRules).map(function (index) {//convert Object to array
                let data = dataWithRules[index];
                return data;
            });

            let ruleLinkMenu: number;
            ruleLinkMenu = dataArray.find(d => d[pkColumn].Value == pkColumnValue)[column].LinkMenuId;
            this.navigate(pkColumnValue, ruleLinkMenu);
        }
    }

    hasRowsInTable(tables) {//Disables submit button when table is empty in FormTable Display type
        let hasRow: boolean = false;
        tables.forEach(table => {
            if (table.length > 0)
                hasRow = true;
            else
                hasRow = false;
        });

        return hasRow;
    }

    showDialogToAdd(module_dt_count: number, submodule_dt_count: number) {
        this.newrow = true;
        this.thisrow = {};
        this.disable = false;
        this.displayDialogModule[module_dt_count][submodule_dt_count] = true;
    }

    resetDialog(module_dt_count: number, submodule_dt_count: number) {
        this.thisrow = {};
        this.disable = false;
        this.displayDialogModule[module_dt_count][submodule_dt_count] = true;
    }
    PivotDialogReset() {
        this.thisrow = {};
        this.disable = false;
        this.displayDialog = true;
    }

    PivotDialogSave(subModuleIndex: number) {
        let thisrows = [...this.SubmoduleDataSourceArray[subModuleIndex]];
        var row = this.thisrow;
        Object.keys(row).map(function (key, index) {
            if (row[key] != null) {
                if (row[key].hasOwnProperty("Value"))
                    row[key] = row[key].Value;

                if (typeof (row[key]) == "object" && !(row[key] instanceof Date)) {
                    let multiDropdownValues: string = "";
                    row[key].forEach(element => {
                        multiDropdownValues = element.Value + "," + multiDropdownValues;

                    });
                    multiDropdownValues = multiDropdownValues.substring(0, multiDropdownValues.length - 1);
                    row[key] = multiDropdownValues;
                }
            }

        })
        this.thisrow = row;
        console.log(this.thisrow);
        if (this.newrow)
            thisrows.push(this.thisrow);
        else
            thisrows[this.SubmoduleDataSourceArray[subModuleIndex].indexOf(this.selectedValues)] = this.thisrow;

        this.SubmoduleDataSourceArray[subModuleIndex] = thisrows;
        console.log(this.SubmoduleDataSourceArray[subModuleIndex]);
        this.thisrow = null;
        this.displayDialog = false;
    }


    save(module_dt_count: number, submodule_dt_count: number) {
        debugger;
        let thisrows = [...this.primeNgTableArray[module_dt_count][submodule_dt_count]];
        var row = this.thisrow;
        Object.keys(row).map(function (key, index) {
            if (row[key] != null) {
                if (row[key].hasOwnProperty("Value"))
                    row[key] = row[key].Value;

                if (typeof (row[key]) == "object" && !(row[key] instanceof Date) && !(Array.isArray(row[key]))) {
                    let multiDropdownValues: string = "";
                    row[key].forEach(element => {
                        multiDropdownValues = element.Value + "," + multiDropdownValues;

                    });
                    multiDropdownValues = multiDropdownValues.substring(0, multiDropdownValues.length - 1);
                    row[key] = multiDropdownValues;
                }
                // Added for FileUpload
                if (typeof (row[key]) == "object" && (Array.isArray(row[key]))) {

                    if (row[key][0] instanceof File) {
                        var fileNames = "";

                        row[key].forEach(file => {
                            fileNames = file["name"] + "," + fileNames;
                        });
                        row[key] = fileNames.substring(0, fileNames.length - 1);
                        //console.log(row[key],'rowkey'); 
                    }
                }
            }

        })
        this.thisrow = row;
        console.log(this.thisrow, 'rowdata');
        if (this.newrow) {
            thisrows.push(this.thisrow);
            //console.log(this.thisrow,'newrowdata');
        }
        else {
            thisrows[this.primeNgTableArray[module_dt_count][submodule_dt_count].indexOf(this.selectedValues)] = this.thisrow;
            //console.log(this.thisrow,'editeddata');
        }

        this.primeNgTableArray[module_dt_count][submodule_dt_count] = thisrows;
        console.log(this.primeNgTableArray[module_dt_count][submodule_dt_count]);
        this.thisrow = null;
        this.displayDialogModule[module_dt_count][submodule_dt_count] = false;
        //console.log(this.thisrow,'newrowdata');
    }

    // SubModuleTabel : FileUpload : Sonal
    isString(value: any) {
        if (typeof value == "string")
            return true;
        else
            return false;
    }


    delete(module_dt_count: number, submodule_dt_count: number) {
        let index = this.primeNgTableArray[module_dt_count][submodule_dt_count].indexOf(this.selectedValues);
        this.primeNgTableArray[module_dt_count][submodule_dt_count] = this.primeNgTableArray[module_dt_count][submodule_dt_count].filter((val, i) => i != index);
        this.thisrow = null;
        this.displayDialogModule[module_dt_count][submodule_dt_count] = false;
    }

    duplicate(module_dt_count: number, submodule_dt_count: number) {
        let thisrows = [...this.primeNgTableArray[module_dt_count][submodule_dt_count]];
        var row = this.thisrow;
        Object.keys(row).map(function (key, index) {
            if (row[key] != null) {
                if (row[key].hasOwnProperty("Value"))
                    row[key] = row[key].Value;
            }
        })
        this.thisrow = row;
        console.log(thisrows);
        if (this.newrow)
            thisrows.push(this.thisrow);
        else
            thisrows.push(this.thisrow);

        this.primeNgTableArray[module_dt_count][submodule_dt_count] = thisrows;
        console.log(this.primeNgTableArray[module_dt_count][submodule_dt_count]);
        this.thisrow = null;
        this.displayDialogModule[module_dt_count][submodule_dt_count] = false;
    }

    onRowSelect(event, SelectableRowKey, SelectableRowKeyValue, cloneRow: boolean, moduleIndex: number, subModuleIndex: number, module_dt_count: number, submodule_dt_count: number) {
        console.log(this.thisrow);
        this.newrow = false;
        if (cloneRow == true)
            this.thisrow = this.clonerow(event.data, moduleIndex, subModuleIndex);
        else
            this.thisrow = event.data;

        this.disable = true;

        if (SelectableRowKey != "" && SelectableRowKey != null) {
            if (this.thisrow[SelectableRowKey] == SelectableRowKeyValue)
                this.displayDialogModule[module_dt_count][submodule_dt_count] = true;
        }
        else {
            this.displayDialogModule[module_dt_count][submodule_dt_count] = true;
        }


    }

    clonerow(c: any, moduleIndex: number, subModuleIndex: number): any {
        let row = {};
        var subModuleDetails = this.Module[0].moduleList[moduleIndex].submodule[subModuleIndex].subModuleDetails;
        for (let prop in c) {
            row[prop] = c[prop];
            var subModuleDetail = subModuleDetails.find(smd => smd.ColumnName == prop);
            if (c[prop] != null && subModuleDetail != undefined) {
                if (subModuleDetail.InputControls == "DropDownList") {
                    row[prop] = subModuleDetail.DropDownValues.find(v => v.Value == c[prop]);
                }
                else if (subModuleDetail.InputControls == "MultiDropDownList") {
                    let selected: any[] = [];
                    selected = c[prop].split(",");
                    row[prop] = [];
                    selected.forEach(value => {
                        row[prop].push(subModuleDetail.DropDownValues.find(v => v.Value == value));
                    });

                }
            }
        }
        return row;
    }

    PivotWithDialogRowSelect(event, SelectableRowKey, SelectableRowKeyValue, cloneRow: boolean, moduleIndex: number, subModuleIndex: number) {
        console.log(this.thisrow);
        this.newrow = false;
        if (cloneRow == true)
            this.thisrow = this.PivotWithDialogclonerow(event.data, moduleIndex, subModuleIndex);
        else
            this.thisrow = event.data;

        this.disable = true;

        if (SelectableRowKey != "" && SelectableRowKey != null) {
            if (this.thisrow[SelectableRowKey] == SelectableRowKeyValue)
                this.displayDialog = true;
        }
        else {
            this.displayDialog = true;
        }
    }
    PivotWithDialogclonerow(c: any, moduleIndex: number, subModuleIndex: number): any {
        let row = {};
        var subModuleDetails = this.Module[0].moduleList[moduleIndex].submodule[subModuleIndex].subModuleDetails;
        for (let prop in c) {
            row[prop] = c[prop];
            var subModuleDetail = subModuleDetails.find(smd => smd.ColumnName == prop);
            if (c[prop] != null && subModuleDetail != undefined) {
                if (subModuleDetail.InputControls == "DropDownList") {
                    row[prop] = subModuleDetail.DropDownValues.find(v => v.Value == c[prop]);
                }
                else if (subModuleDetail.InputControls == "MultiDropDownList") {
                    let selected: any[] = [];
                    selected = c[prop].split(",");
                    row[prop] = [];
                    selected.forEach(value => {
                        row[prop].push(subModuleDetail.DropDownValues.find(v => v.Value == value));
                    });

                }
            }
        }
        return row;
    }

    GetModulewithFilter(filter: any) {
        filter = JSON.stringify(filter);
        this.show = true;
        this.mainpageservice.GetModulesWithFilter(this.Sys_Menu_ID, this.UserName, '{Filter:' + filter + '}').subscribe(data => {
            this.expandedElementArray = [];
            this.frozenColsArray = [];
            this.scrollableColsArray = [];
            this.primeNgTableArray = [];
            this.frozenWidthArray = [];
            this.selectedColumnsArray = [];
            this.treetabledataArray = [];
            this.filterColsArray = [];
            this.headerLevelOneArray = [];
            this.ModuleDataWithRuleArray = []

            this.SubmoduleDataSourceArray = [];
            this.SubmoduleFrozenColsArray = [];
            this.SubModuleFrozenWidthArray = [];
            this.SubmoduleScrollableColsArray = [];
            this.reportTableArray = [];
            this.reportTableColumnsArray = [];

            this.temptableArray = [];

            console.log("Moduleapi", data);
            this.Module = data;
            //console.log(data);
            const rows = [];
            let count: number = 0;
            //res.forEach(element => rows.push(element, { detailRow: true, element }));
            ////console.log(rows);
            this.scrollableColsArray = [];
            this.primeNgTableArray = [];
            this.Module[0].moduleList.forEach((val) => {
                let headerLevels: number;
                headerLevels = val.HeaderLevels;
                let moduleIndex: number;
                moduleIndex = this.Module[0].moduleList.indexOf(val);

                let data = val.moduleData.map(a => ({ ...a })); //original data which will be use to compare changes in data before leaving current page
                let temptabledata = [...data]
                this.temptableArray.push(temptabledata);//this array is compared in AfterViewChecked


                if (val.DisplayType == 'Form') {
                    val.moduleDetails.map(d => {
                        if (d.DataType == 'date' || d.DataType == 'datetime') {
                            if (d.value != null && d.value != "") {
                                d.value = new Date(d.value);
                            }
                        }
                    });
                }
                if (val.DisplayType == 'PrimeNgPivotTable' || val.DisplayType == 'PrimeNgTable' || val.DisplayType == 'PrimeUnfrozenTable' || val.DisplayType.toLowerCase() == 'primengmultiheadertable' || val.DisplayType == 'MultiHeaderTable' || val.DisplayType == 'Report'
                    || val.DisplayType == '' || val.DisplayType.toLowerCase() == 'dataview') {

                    //if (val.DisplayType == 'Report') {
                    //    if (val.moduleData != null && val.moduleData != undefined && val.moduleData != "") {
                    //        this.reportTableArray.push(val.moduleData);
                    //        this.reportTableColumnsArray.push(Object.keys(val.moduleData[0]));
                    //    }
                    //}

                    this.frozenCols = [];
                    this.scrollableCols = [];
                    this.childColumns = [];
                    this.displayedColumns = [];
                    this.filterCols = [];
                    this.headerLevelOne = [];
                    this.Dataview.sortOptions = [];

                    let headerLevels: number;
                    headerLevels = val.HeaderLevels;
                    if (val.moduleDetails != null && typeof (val.moduleDetails) != undefined) {
                        val.moduleDetails.forEach((column) => {
                            ///frozen and scrollable columns primeng
                            if (column.HeaderLevel != 1 && column.InputControls != 'HiddenField') {
                                if (column.FrozenCols == true) {
                                    this.frozenCols.push(column);
                                }
                                else {
                                    this.scrollableCols.push(column);
                                }
                            }
                            else if (column.HeaderLevel == 1 && column.InputControls != 'HiddenField') {
                                this.headerLevelOne.push(column);
                            }
                            this.filterCols.push(column.ColumnName);
                            this.Dataview.sortOptions.push({ label: column.ColumnHeader, value: column.ColumnName });
                        });

                        /*type cast date data*/
                        var dateColumns = [];
                        dateColumns = val.moduleDetails.filter(md => md.DataType.includes("date"));
                        val.moduleData.map(d => {
                            dateColumns.forEach(column => {
                                if (d[column.ColumnName] != null)
                                    d[column.ColumnName] = new Date(d[column.ColumnName]);
                            })
                        });

                        this.ModuleDataWithRule = val.ModuleDataWithRule;
                        this.ModuleDataWithRuleArray.push(this.ModuleDataWithRule);
                        this.primengTableData = val.moduleData;

                        this.frozenColsArray.push(this.frozenCols);
                        this.scrollableColsArray.push(this.scrollableCols);
                        this.primeNgTableArray.push(this.primengTableData);
                        this.selectedColumnsArray.push(this.scrollableCols);
                        this.filterColsArray.push(this.filterCols);
                        this.headerLevelOneArray.push(this.headerLevelOne);
                        this.displayDialogModule.push('no dialog');

                        if(val.GroupBy!="")
                            this.updateRowGroupMetaData(this.primengTableData, val.GroupBy,val.DefaultSortByColumn);
                        //this.selectedColumnsArray.push(this.scrollableCols.filter((c,index) => index < 10));

                        let frozenWidth = this.frozenCols.length * 150 + 50 + "px";
                        this.frozenWidthArray.push(frozenWidth);

                        if (val.DisplayType.toLowerCase() == 'dataview') {
                            let filterByColumn: string;
                            filterByColumn = val.FilterByColumn;
                            this.Dataview.filterByValue = this.Dataview.GetFilterOptions(filterByColumn, this.primeNgTableArray[0]);
                        }

                    }
                }
                else if (val.DisplayType == "TreeTable") {
                    this.frozenCols = [];
                    this.scrollableCols = [];
                    this.filterCols = [];
                    if (val.moduleDetails != null && typeof (val.moduleDetails) != undefined) {
                        val.moduleDetails.forEach((column) => {
                            ///frozen and scrollable columns primeng
                            if (column.InputControls != "HiddenField") {

                                if (column.FrozenCols == true) {
                                    this.frozenCols.push(column);
                                }
                                else {
                                    this.scrollableCols.push(column);
                                }
                            }
                            this.filterCols.push(column.ColumnName);
                        });


                        var treedata = [];
                        treedata = val["TreeOutputDataRoot"];
                        let file: FileSystemNode[] = [];

                        for (let i = 0; i < (<any>treedata).data.length; i++) {

                            let rootObj = (<any>treedata).data[i];
                            let node = new FileSystemNode(rootObj, null, val.moduleDetails);
                            file.push(node);
                        }
                        this.files = file;
                        let frozenWidth = this.frozenCols.length * 150 + 50 + "px";

                        this.scrollableColsArray.push(this.scrollableCols);
                        this.frozenColsArray.push(this.frozenCols);
                        this.primeNgTableArray.push(this.files);
                        this.filterColsArray.push(this.filterCols);
                        this.frozenWidthArray.push(frozenWidth);
                        this.selectedColumnsArray.push(this.scrollableCols);
                        this.filterColsArray.push(this.filterCols);
                        this.headerLevelOneArray.push(null);
                        this.displayDialogModule.push('no dialog');
                        this.loading = false;
                    }
                }
                else if (val.DisplayType.toLowerCase() == 'pivottablewithdialog') {

                    val.submodule.forEach(submodule => {
                        let subModuleIndex: number;
                        subModuleIndex = val.submodule.indexOf(submodule);
                        if (submodule.DisplayType == 'PrimeNgPivotTable' || submodule.DisplayType == 'PivotTable' ||
                            submodule.DisplayType == 'PrimeNgTable' || submodule.DisplayType == 'PrimeUnfrozenTable' ||
                            submodule.DisplayType == '' || submodule.DisplayType.toLowerCase() == 'table') {

                            this.frozenCols = [];
                            this.scrollableCols = [];
                            this.childColumns = [];
                            this.displayedColumns = [];
                            this.filterCols = [];
                            this.headerLevelOne = [];

                            let headerLevels: number;
                            headerLevels = val.HeaderLevels;
                            if (submodule.subModuleDetails != null && typeof (submodule.subModuleDetails) != undefined) {
                                submodule.subModuleDetails.forEach((column) => {
                                    ///frozen and scrollable columns primeng
                                    if (column.InputControls != "HiddenField" && column.HeaderLevel != 1) {
                                        if (column.FrozenCols == true) {
                                            this.frozenCols.push(column);
                                        }
                                        else {
                                            this.scrollableCols.push(column);
                                        }
                                    }
                                    else if (column.InputControls != "HiddenField" && column.HeaderLevel == 1) {
                                        this.headerLevelOne.push(column);
                                    }
                                    this.filterCols.push(column.ColumnName);

                                });

                                /*type cast date data*/
                                var dateColumns = [];
                                dateColumns = submodule.subModuleDetails.filter(md => md.DataType.includes("date"));
                                submodule.moduleData.map(d => {
                                    dateColumns.forEach(column => {
                                        if (d[column.ColumnName] != null)
                                            d[column.ColumnName] = new Date(d[column.ColumnName]);
                                    })
                                });
                                this.SubmoduleDataSourceArray.push(submodule.moduleData);
                                if (this.Module[0].moduleList[moduleIndex].submodule[subModuleIndex].ColumnsForSum) {
                                    this.SubmoduleDataSourceArray[subModuleIndex].forEach(rowdata => {
                                        this.calculateRowWiseTotalSubmodule(rowdata, moduleIndex, subModuleIndex)
                                    });
                                }

                                this.displayedSubmoduleColumns.push(this.scrollableCols);
                                this.frozenColsSubmoduleArray.push(this.frozenCols);
                                let frozenWidth = this.frozenCols.length * 150 + 50 + "px";
                                this.frozenWidthSubModuleArray.push(frozenWidth);
                                this.displayDialogModule.push('no dialog');
                            }
                        }
                    });
                    console.log("ARRAYS", this.displayedSubmoduleColumns, this.SubmoduleDataSourceArray, this.frozenColsSubmoduleArray, this.frozenWidthSubModuleArray)
                }

                else if (val.DisplayType == 'FormTableSubmodule') {
                    this.SubmoduleDataSource = [];
                    this.SubmoduleScrollableCols = [];
                    this.SubmoduleFrozenCols = [];
                    this.SubModuleFrozenWidth = [];
                    this.displayDialogSubmodule = [];

                    val.submodule.forEach(moduleinsidesubmodule => {
                        let subModuleIndex: number;
                        subModuleIndex = val.submodule.indexOf(moduleinsidesubmodule);

                        if (moduleinsidesubmodule.DisplayType == 'PrimeNgPivotTable' || moduleinsidesubmodule.DisplayType == 'PivotTable' ||
                            moduleinsidesubmodule.DisplayType == 'PrimeNgTable' || moduleinsidesubmodule.DisplayType == 'PrimeUnfrozenTable' ||
                            moduleinsidesubmodule.DisplayType == '' || moduleinsidesubmodule.DisplayType.toLowerCase() == 'table') {

                            this.frozenCols = [];
                            this.scrollableCols = [];
                            this.childColumns = [];
                            this.displayedColumns = [];
                            this.filterCols = [];
                            this.headerLevelOne = [];

                            let headerLevels: number;
                            headerLevels = val.HeaderLevels;
                            if (moduleinsidesubmodule.subModuleDetails != null && typeof (moduleinsidesubmodule.subModuleDetails) != undefined) {
                                moduleinsidesubmodule.subModuleDetails.forEach((column) => {
                                    ///frozen and scrollable columns primeng
                                    if (column.InputControls != "HiddenField" && column.HeaderLevel != 1) {
                                        if (column.FrozenCols == true) {
                                            this.frozenCols.push(column);
                                        }
                                        else {
                                            this.scrollableCols.push(column);
                                        }
                                    }
                                    else if (column.InputControls != "HiddenField" && column.HeaderLevel == 1) {
                                        this.headerLevelOne.push(column);
                                    }
                                    this.filterCols.push(column.ColumnName);

                                });
                                /*type cast date data*/
                                var dateColumns = [];
                                dateColumns = moduleinsidesubmodule.subModuleDetails.filter(md => md.DataType.includes("date"));
                                moduleinsidesubmodule.moduleData.map(d => {
                                    dateColumns.forEach(column => {
                                        if (d[column.ColumnName] != null)
                                            d[column.ColumnName] = new Date(d[column.ColumnName]);
                                    })
                                });

                                if (this.Module[0].moduleList[moduleIndex].submodule[subModuleIndex].ColumnsForSum) {
                                    this.SubmoduleDataSource[0].forEach(rowdata => {
                                        this.calculateRowWiseTotalSubmodule(rowdata, moduleIndex, subModuleIndex)
                                    });
                                }
                            }
                            let frozenWidth = this.frozenCols.length * 150 + 50 + "px";
                            this.SubmoduleDataSource.push(moduleinsidesubmodule.moduleData);
                            this.SubmoduleScrollableCols.push(this.scrollableCols);
                            this.SubmoduleFrozenCols.push(this.frozenCols);
                            this.SubModuleFrozenWidth.push(frozenWidth);
                            this.displayDialogSubmodule.push(false);
                        }
                    });
                    this.primeNgTableArray.push(this.SubmoduleDataSource);
                    this.scrollableColsArray.push(this.SubmoduleScrollableCols);
                    this.frozenColsArray.push(this.SubmoduleFrozenCols);
                    this.selectedColumnsArray.push(this.scrollableCols);
                    this.frozenWidthArray.push(this.SubModuleFrozenWidth);
                    this.filterColsArray.push(this.filterCols);
                    this.headerLevelOneArray.push(null);
                    this.displayDialogModule.push(this.displayDialogSubmodule);
                }
                else if (val.DisplayType.toLowerCase() == "tree") {
                    //var treeModuleData=<TreeNode[]>val.TreeData;
                    this.treeModuleData = <TreeNode[]>val.TreeData;
                    this.treeModuleData = this.treeModuleData[0]['children'];
                    console.log(this.treeModuleData);
                    //this.treeModuleArray.push(treeModuleData);
                }
                else if (val.DisplayType.toLowerCase() == "timeline") {
                    this.timelineTableArray = [];
                    this.timelineTableColumnsArray = [];
                    val.moduleData.sort((a, b) => (a.orderby > b.orderby) ? 1 : ((a.orderby < b.orderby) ? -1 : 0));
                    this.timelineData = val.TimelineData;
                    console.log(this.timelineData);
                    if (this.timelineData != null && this.timelineData != undefined && this.timelineData != "") {
                        this.timelineData.forEach(event => {
                            if (event.Submodule.moduleData != null && event.Submodule.moduleData != undefined) {
                                this.timelineTableArray.push(event.Submodule.moduleData);
                                // this.timelineTableColumnsArray.push(Object.keys(event.Submodule.moduleData[0]));
                            }
                            else {
                                this.timelineTableArray.push([]);
                                // this.timelineTableColumnsArray.push([]);
                            }

                            if (event.Submodule.subModuleDetails != null && event.Submodule.subModuleDetails != undefined) {
                                let columns = [];
                                event.Submodule.subModuleDetails.forEach(submoduledetail => {
                                    if (submoduledetail.InputControls != 'HiddenField') {
                                        columns.push(submoduledetail);
                                    }

                                });
                                this.timelineTableColumnsArray.push(columns);
                            }
                        });

                    }
                    console.log(this.timelineTableArray, this.timelineTableColumnsArray);
                }
            });
            this.show = false;
        });
    }
    expandRecursive(node: TreeNode, isExpand: boolean) {
        node.expanded = isExpand;
        if (node.children) {
            node.children.forEach(childNode => {
                this.expandRecursive(childNode, isExpand);
            });
        }
    }
    modelChange(event, dtcount) {
        console.log(this.selectedColumnsArray[dtcount])
        this.selectedColumnsArray[dtcount] = event;
        this.selectedColumnsArray[dtcount].sort(
            function compare(a, b) {
                if (a.OrderBy < b.OrderBy)
                    return -1;
                if (a.OrderBy > b.OrderBy)
                    return 1;
                return 0;
            }

        );
        console.log(this.selectedColumnsArray[dtcount]);
    }

    GetPaginatedTreeTable(filter: any) {
        this.expandedElementArray = [];
        filter = JSON.stringify(filter);
        this.show = true;

        this.mainpageservice.GetPaginatedTreeTable(this.Sys_Menu_ID, this.UserName, '{Filter:' + filter + '}').subscribe(data => {
            console.log("Moduleapi", data);
            this.Module = data;
            var columns = [];
            columns = data["moduleDetails"];
            var treedata = [];
            treedata = data["TreeOutputDataRoot"];
            var moduleDetails = data["moduleDetails"];
            var temp = [];
            if (moduleDetails != null && typeof (moduleDetails) != undefined) {
                moduleDetails.forEach((column) => {
                    if (column.InputControls != 'HiddenField')
                        temp.push(column);
                });
            }
            this.cols = temp;

            let file: FileSystemNode[] = [];

            for (let i = 0; i < (<any>treedata).data.length; i++) {

                let rootObj = (<any>treedata).data[i];
                let node = new FileSystemNode(rootObj, null, columns);
                file.push(node);
            }
            this.files = file;
            console.log("treedata", this.files);
        });
    }


    nestedFilterCheck(search, data, key) {
        if (typeof data[key] === 'object') {
            for (const k in data[key]) {
                if (data[key][k] !== null) {
                    search = this.nestedFilterCheck(search, data[key], k);
                }
            }
        } else {
            search += data[key];
        }
        return search;
    }//searching nested object array

    showInFilter(ParameterName) {//to show/hide filter fields userwise(userwise menu detail mapping)
        return this.PageMenuDetails.filter(menudetail => menudetail.ParameterName == ParameterName)[0].ShowInFilter;
    }


    getPageMenuDetails() {
        debugger;
        var appliedFilters = sessionStorage.getItem("filterdata");
        var filterJson = JSON.parse(appliedFilters);
        let promise = new Promise((resolve, reject) => {
            setTimeout(() => {
                let filter1 = localStorage.getItem('navigationExtras');
                this.mainpageservice.getPageMenuDetails(this.Sys_Menu_ID, this.UserName).toPromise().then(filter => {
                    this.PageMenuDetails = filter;
                    if (filterJson != null && filterJson != undefined && Object.keys(filterJson).length !== 0) {
                        //apply default value to all menus with same menudetails
                        if (this.PageMenuDetails != undefined && this.PageMenuDetails.length > 0) {
                            Object.keys(filterJson).forEach(key => {
                                var pagemenudetail = this.PageMenuDetails.find(pgm => pgm.ParameterName == key);
                                if (pagemenudetail != undefined && pagemenudetail != null)
                                    pagemenudetail.DefaultValue = filterJson[key];
                            });
                        }
                    }
                    this.show = false;
                    if (this.PageMenuDetails != undefined && this.PageMenuDetails.length > 0) {
                        if (this.PageMenuDetails[0].ParameterName != "rowval") {
                            //get default value from database if parameter name is not rowval
                            var pageFilter = {};
                            this.PageMenuDetails.forEach(menudetail => {
                                if (menudetail.DataType == "month") {//convert default date string to date object
                                    var dateObject = new Date();
                                    if (menudetail.DefaultValue.toLowerCase() == "last") {
                                        dateObject.setMonth(dateObject.getMonth() - 1);

                                        menudetail.DefaultValue = dateObject;
                                        pageFilter[menudetail.ParameterName] = "" + (dateObject.getMonth() + 1) + "/" + dateObject.getFullYear();

                                    }
                                    else if (menudetail.DefaultValue.toLowerCase() == "current" || menudetail.DefaultValue.toLowerCase() == "") {
                                        dateObject.setMonth(dateObject.getMonth());

                                        menudetail.DefaultValue = dateObject;
                                        pageFilter[menudetail.ParameterName] = "" + (dateObject.getMonth() + 1) + "/" + dateObject.getFullYear();

                                    }
                                    else if (menudetail.DefaultValue.toLowerCase() != "last" && menudetail.DefaultValue.toLowerCase() != "" && menudetail.DefaultValue.toLowerCase() != "current") {
                                        var dateArray = menudetail.DefaultValue.split("/");

                                        dateObject.setMonth(dateArray[0] - 1);
                                        dateObject.setFullYear(dateArray[1]);

                                        menudetail.DefaultValue = dateObject;
                                        if (dateArray[0] < 10)
                                            pageFilter[menudetail.ParameterName] = "0" + (dateObject.getMonth() + 1) + "/" + dateObject.getFullYear();
                                        else
                                            pageFilter[menudetail.ParameterName] = "" + (dateObject.getMonth() + 1) + "/" + dateObject.getFullYear();
                                    }

                                }
                                else if (menudetail.DataType == "date" || menudetail.DataType == "datetime") {//convert default date string to date object
                                    var dateObject = new Date();
                                    if (menudetail.DefaultValue != "") {
                                        dateObject = new Date(menudetail.DefaultValue);
                                    }

                                    menudetail.DefaultValue = dateObject;
                                    var month;
                                    if ((dateObject.getMonth() + 1) < 10)
                                        month = "0" + (dateObject.getMonth() + 1);
                                    else
                                        month = dateObject.getMonth() + 1;
                                    pageFilter[menudetail.ParameterName] = "" + dateObject.getFullYear() + "-" + month + "-" + (dateObject.getDate());
                                }
                                else {
                                    pageFilter[menudetail.ParameterName] = menudetail.DefaultValue;//to create filter json
                                }
                            });
                            this.GetModulewithFilter(pageFilter);
                            console.log("PageMenuDetails", this.PageMenuDetails);
                            console.log("PageFilter", pageFilter);
                        }
                        else {
                            //get modules when rowval exist
                            this.GetModulewithFilter(filter1);
                        }
                    }
                    else {
                        //get modules when menu without menu details/filters
                        this.GetModulewithFilter(filter1);
                    }
                });

                resolve();
            }, 100)

        });
        return promise;

    }

    getModulesToShow() {//to get modules list to show userwise
        this.mainpageservice.getModulesToShow(this.Sys_Menu_ID, this.UserName).subscribe(data => {
            debugger;
            ////console.log("ModuleData", data);
            let dataArray: Array<any> = [];
            for (let item in data) {
                dataArray = data[item];
                this.ModuleIDList.push(dataArray)
            }

        })
    }

    getModuleDetailIdToHide() {
        //To get ModuledetailIDTo hide
        this.mainpageservice.getModuleDetailIdToHide(this.Sys_Menu_ID, this.UserName).subscribe(data => {
            debugger;
            ////console.log("ModuleData", data);
            let dataArray: Array<any> = [];
            for (let item in data) {
                dataArray = data[item];
                this.ModuleDetailIDList.push(dataArray)
            }
            this.getFeedback();
        })
    }

    getFeedback() {
        this.mainpageservice.GetFeedback(this.Sys_Menu_ID, this.UserName, undefined).subscribe(data => {
            this.Feedback = data;
        });
    }

    @HostListener('window:beforeunload')
    canDeactivate(): Observable<boolean> | boolean {
        // insert logic to check if there are pending changes here;
        // returning true will navigate without confirmation
        // returning false will show a confirm dialog before navigating away
        return !this.haveChangesOnPage;
    }

    ngAfterViewChecked() {
        if (this.Module) {
            let tableModules;
            if (this.Module && this.Module.length > 0) {
                tableModules = this.Module[0].moduleList.filter(m => m.DisplayType.toLowerCase().includes("table"));

            }
            if (this.primeNgTableArray && this.temptableArray && tableModules.length > 0)//to detect changes before leaving the page
            {
                const data = this.temptableArray.map(a => ({ ...a }));
                const tempdata = this.temptableArray;
                const tableCount = this.primeNgTableArray.length;

                for (var i = 0; i < tableCount; i++) {
                    if (tableModules[i].DisplayType.toLowerCase() != "treetable" && tableModules[i].DisplayType.toLowerCase() != "tree") {
                        if (JSON.stringify(this.primeNgTableArray[i]) != JSON.stringify(tempdata[i])) {
                            this.haveChangesOnPage = true;
                        }
                    }
                    else {
                        if (tableModules[i].DisplayType.toLowerCase() == "treetable") {
                            var updateData = this.files.filter(element => element.data.Updated == true)
                            if (updateData.length > 0) {
                                this.haveChangesOnPage = true;
                                console.log(this.haveChangesOnPage);
                            }
                        }
                    }
                }
            }
        }

    }

    ngOnInit() {
        this.show = true;
        this.loading = true;
        this.haveChangesOnPage = false;
        let filter1 = localStorage.getItem('navigationExtras');
        this.Locale = localStorage.getItem("Locale");
        this.Currency = localStorage.getItem("Currency");
        this.DateFormat = localStorage.getItem("DateFormat");
        this.TimeZone = localStorage.getItem("TimeZone");
        this.hourFormat = parseInt(localStorage.getItem("HourFormat"));
        if (this.hourFormat == 24) {
            this.dateTimeFormat = this.DateFormat + " HH:mm";
        }
        else
            this.dateTimeFormat = this.DateFormat + " hh:mm";
        // this.router.events.pipe(filter((event: RouterEvent) => event instanceof NavigationEnd)
        // ).subscribe(() => {//refresh module on reload/navigation like back or submenu click
        //     this.GetModulewithFilter(filter1);
        // });

        this.routerEventSubscription = this.router.events.subscribe(event => {
            if (event instanceof NavigationEnd) {
                this.rowval = this.activateRouter.snapshot.queryParamMap.get('rowval');
            }
        });

        this.UserName = localStorage.getItem('username');

        this.getPageMenuDetails().then(
            () => this.getModuleDetailIdToHide(),
            () => this.getModulesToShow(),
        )
    }

    GetDependentFilterDropDown(ParameterId, event, index) {
        debugger;
        var IDvalue = event.value.Value == undefined ? event.value.Value : event.value.Value;
        this.mainpageservice.getDependentFilterDropDownValue(ParameterId, IDvalue).subscribe(response => {
            debugger;
            console.log(response);
            var pagemenudetail = this.PageMenuDetails.find(pgm => pgm.ParameterName == index);
            pagemenudetail.DropDownValues = <any>(response)["Table"];
        }, err => {
            console.log(err);
        });

    }
    DropdownChange(ModuleDetailId, event, index) {

        if (event.value.Value != '') {
            this.mainpageservice.getDependentDropDownValue(ModuleDetailId, event.value.Value).subscribe(response => {

                //console.log(response);
                this.DropDown[index] = <any>(response)["Table"];
            }, err => {

            });
        }

    }

    //susan added - start
    GetDependentDropDown(ParameterId, event, index, moduleIndex) {
        debugger;
        var IDvalue = event.value.Value == undefined ? event.value.Value : event.value.Value;
        //var IDvalue = event.detail.value.Value == undefined ? event.detail.value.Value : event.detail.value.Value;
        var trimValue = $.trim(IDvalue);
        this.mainpageservice.getDependentDropDownValue(ParameterId, trimValue).subscribe(response => {
            debugger;
            //console.log(response);
            var moduledetail = this.Module[0].moduleList[moduleIndex].moduleDetails.find(md => md.ColumnName == index);
            moduledetail.DropDownValues = <any>(response)["Table"];
            // var DropDown = document.getElementsByName(index)
            // this.DropDown[index] = <any>(response)["Table"];
        }, err => {
            //console.log(err);
        });
    }
    //susan added - end




    GetDependentDropDownThroughValue(ParameterId, event, index) {
        //var IDvalue = event.detail.value.Text == undefined ? event.detail.value.Text : event.detail.value.Text;
        var IDvalue = event.value.Text == undefined ? event.value.Text : event.value.Text;
        var trimValue = $.trim(IDvalue);
        this.mainpageservice.GetDependentDropDownThroughValue(ParameterId, trimValue).subscribe(response => {
            debugger;
            //console.log(response);
            var DropDown = document.getElementsByName(index)
            this.DropDown[index] = <any>(response)["Table"];
        }, err => {
            ////console.log(err);
        });
    }


    navigateOnFormSubmit(LinkedMenu) {
        let filter = localStorage.getItem("navigationExtras");
        this.events.publish('navigationExtras', JSON.parse(filter));
        let navigationExtras: NavigationExtras = {
            queryParams: JSON.parse(filter)

        };
        this.router.navigateByUrl("/menu/first/tabs/" + LinkedMenu, navigationExtras);

    }

    //Button config save/update FormTable
    UpdateSubmodule(form: any, dt, ID, ButtonId) {
        var submodules = this.Module[0].moduleList[0].submodule;
        submodules.forEach(submodule => {
            var dateColumns = [];
            dateColumns = submodule.subModuleDetails.filter(md => md.DataType == "date");
            if (submodule.DisplayType == "Form") {
                dateColumns.forEach(column => {
                    if (form[column.ColumnName] != null) {
                        let adate = new Date(form[column.ColumnName]);
                        var ayear = adate.getFullYear();
                        var amonth: any = adate.getMonth() + 1;
                        var adt: any = adate.getDate();
                        if (adt < 10) { adt = '0' + adt; }
                        if (amonth < 10) { amonth = '0' + amonth; }
                        form[column.ColumnName] = ayear + '-' + amonth + '-' + adt;
                    }
                })
            }
            else if (submodule.DisplayType == "" || submodule.DisplayType == "PrimeUnfrozenTable") {
                //dt.toArray().foreach(table => {
                dt[0].map(d => {
                    dateColumns.forEach(column => {
                        if (d[column.ColumnName] != null) {
                            let adate = new Date(d[column.ColumnName]);
                            var ayear = adate.getFullYear();
                            var amonth: any = adate.getMonth() + 1;
                            var adt: any = adate.getDate();
                            if (adt < 10) { adt = '0' + adt; }
                            if (amonth < 10) { amonth = '0' + amonth; }
                            d[column.ColumnName] = ayear + '-' + amonth + '-' + adt;
                        }
                    })
                });
                // })

            }
        });


        let filter1 = localStorage.getItem('navigationExtras');
        this.routerEventSubscription = this.router.events.pipe(filter((event: RouterEvent) => event instanceof NavigationEnd)
        ).subscribe(() => {//refresh module on reload/navigation like back or submenu click
            this.GetModulewithFilter(filter1);
        });

        this.UserName = localStorage.getItem('username');
        this.show = true;

        this.mainpageservice.UpdateSubmodules(ID, form, dt, ButtonId, this.UserName).subscribe(resp => {
            console.log(JSON.stringify(resp["Message"]));
            let toast = this.toastController.create({
                message: resp["Message"],
                duration: 3000,
                position: 'bottom',
                //closeButtonText: 'Ok',

            });
            this.GetModulewithFilter(filter1);

            toast.then(toast => toast.present());
            this.show = false;

        });
    }

    //Button config save for form
    UpdateData(form: any, ID: any, ButtonId: any, LinkedMenu: any) {
        let filter1 = localStorage.getItem('navigationExtras');
        this.routerEventSubscription = this.router.events.pipe(filter((event: RouterEvent) => event instanceof NavigationEnd)
        ).subscribe(() => {
            this.GetModulewithFilter(filter1);
        });
        const that = this;
        let Mess = undefined;
        this.UserName = localStorage.getItem('username');
        this.show = true;

        //Form Without FileUpload
        this.mainpageservice.UpdateData(ID, ButtonId, form, this.UserName).subscribe(resp => {
            //console.log(JSON.stringify(resp["Message"]));
            let toast = this.toastController.create({
                message: resp["Message"],
                duration: 3000,
                position: 'bottom',
                //closeButtonText: 'Ok',

            });
            this.GetModulewithFilter(filter1);
            if (LinkedMenu != 0 && LinkedMenu != '' && LinkedMenu != null) {
                this.navigateOnFormSubmit(LinkedMenu);
            }

            toast.then(toast => toast.present());
            this.show = false;

        });
    }
    //Button config save for table/pivottable
    UpdateDatatable(ID: any, ButtonId: any, index: number, dt, filter: any, LinkedMenu: any) {
        debugger;
        if (this.rowval != "" && this.rowval != null)
            filter = { 'rowval': this.rowval };

        this.show = true;
        /*type cast date to string*/
        var dateColumns = [];
        dateColumns = this.Module[0].moduleList[index].moduleDetails.filter(md => md.DataType == "date");
        dt.value.map(d => {
            dateColumns.forEach(column => {
                if (d[column.ColumnName] != null) {
                    let adate = new Date(d[column.ColumnName]);
                    var ayear = adate.getFullYear();
                    var amonth: any = adate.getMonth() + 1;
                    var adt: any = adate.getDate();
                    if (adt < 10) { adt = '0' + adt; }
                    if (amonth < 10) { amonth = '0' + amonth; }
                    d[column.ColumnName] = ayear + '-' + amonth + '-' + adt;
                }
                //        if (d[column.ColumnName] != null) {

                //            d[column.ColumnName] = d[column.ColumnName].toISOString();
                //        }
            })
        });

        var datetimeColumns = [];
        datetimeColumns = this.Module[0].moduleList[index].moduleDetails.filter(md => md.DataType == "datetime");
        dt.value.map(d => {
            datetimeColumns.forEach(column => {
                if (d[column.ColumnName] != null) {
                    let adate = new Date(d[column.ColumnName]);
                    var ayear = adate.getFullYear();
                    var amonth: any = adate.getMonth() + 1;
                    var ahour: any = adate.getHours();
                    var aminute: any = adate.getMinutes();
                    var adt: any = adate.getDate();
                    if (adt < 10) { adt = '0' + adt; }
                    if (amonth < 10) { amonth = '0' + amonth; }
                    d[column.ColumnName] = ayear + '-' + amonth + '-' + adt + " " + ahour + ":" + aminute + ":00";
                }
            })
        });

        //var dropdowns = [];
        //dropdowns = this.Module[0].moduleList[index].moduleDetails.filter(md => md.InputControls.includes("DropDownList"));
        //dt.value.map(d => {
        //    dropdowns.forEach(column => {
        //        if (d[column.ColumnName] != null) {
        //            if (d[column.ColumnName].Text != undefined)
        //                d[column.ColumnName] = d[column.ColumnName].Text;
        //            else
        //                d[column.ColumnName] = d[column.ColumnName];
        //        }
        //    })
        //})

        filter = JSON.stringify(filter);
        var data = {};
        if (dt.hasFilter()) {
            data["data"] = dt.filteredValue.slice(0, parseInt(dt.rows) + parseInt(dt.first))
        }
        else {
            data["data"] = dt.value.slice(0, parseInt(dt.rows) + parseInt(dt.first));
        }
        data["filter"] = filter;

        //Form Without FileUpload
        this.mainpageservice.UpdateDatatablewithfilter(ID, this.Sys_Menu_ID, ButtonId, data, this.UserName).subscribe(resp => {
            //console.log(JSON.stringify(resp["Message"]));
            let toast = this.toastController.create({
                message: resp["Message"],
                duration: 3000,
                position: 'bottom',
                //closeButtonText: 'Ok',

            });
            this.GetModulewithFilter(filter);
            if (LinkedMenu != 0 && LinkedMenu != '' && LinkedMenu != null) {
                this.navigateOnFormSubmit(LinkedMenu);
            }

            toast.then(toast => toast.present());
            this.show = false;

        });
    }

    public GetPivotTableModule(ID: number, dt: any, filter: any, dtIndex: number) {
        this.mainpageservice.GetPivotTableModule(this.Sys_Menu_ID, ID, this.UserName, filter).subscribe(res => {
            var val = res;
            if (val["moduleDetails"] != null && typeof (val["moduleDetails"]) != undefined) {
                this.primengTableData = val["moduleData"];
                this.primeNgTableArray[dtIndex] = this.primengTableData;
            }


            if (dt.hasFilter()) {
                console.log(dt.filters);
                this.primengTable.filters = dt.filters
            }

        });

    }

    SubmitPrimeNgTable(ID: any, filter: any, dt: any, index: number) {
        let filter1 = localStorage.getItem('navigationExtras');
        var navigationExtras = JSON.parse(filter1);
        if (filter == null)
            filter = {};
        if (navigationExtras != null && navigationExtras != undefined) {
            Object.keys(navigationExtras).forEach(key => {
                filter[key] = navigationExtras[key];
            });
        }

        this.show = true;
        /*type cast date to string*/
        var dateColumns = [];
        dateColumns = this.Module[0].moduleList[index].moduleDetails.filter(md => md.DataType == "date");
        dt.value.map(d => {
            dateColumns.forEach(column => {
                if (d[column.ColumnName] != null) {
                    let adate = new Date(d[column.ColumnName]);
                    var ayear = adate.getFullYear();
                    var amonth: any = adate.getMonth() + 1;
                    var adt: any = adate.getDate();
                    if (adt < 10) { adt = '0' + adt; }
                    if (amonth < 10) { amonth = '0' + amonth; }
                    d[column.ColumnName] = ayear + '-' + amonth + '-' + adt;
                }
            })
        });

        var datetimeColumns = [];
        datetimeColumns = this.Module[0].moduleList[index].moduleDetails.filter(md => md.DataType == "datetime");
        dt.value.map(d => {
            datetimeColumns.forEach(column => {
                if (d[column.ColumnName] != null) {
                    let adate = new Date(d[column.ColumnName]);
                    var ayear = adate.getFullYear();
                    var amonth: any = adate.getMonth() + 1;
                    var ahour: any = adate.getHours();
                    var aminute: any = adate.getMinutes();
                    var adt: any = adate.getDate();
                    if (adt < 10) { adt = '0' + adt; }
                    if (amonth < 10) { amonth = '0' + amonth; }
                    d[column.ColumnName] = ayear + '-' + amonth + '-' + adt + " " + ahour + ":" + aminute + ":00";
                }
            })
        });

        var checkboxColumns = [];
        checkboxColumns = this.Module[0].moduleList[index].moduleDetails.filter(md => md.InputControls == "Checkbox");
        dt.value.map(d => {
            checkboxColumns.forEach(column => {
                if (d[column.ColumnName] == null || d[column.ColumnName] == "") {
                    d[column.ColumnName] = false;
                }
            })
        });

        filter = JSON.stringify(filter);
        var data = {};
        if (dt.hasFilter()) {
            data["data"] = dt.filteredValue.slice(0, parseInt(dt.rows) + parseInt(dt.first))
        }
        else {
            data["data"] = dt.value.slice(0, parseInt(dt.totalRecords) + parseInt(dt.first));
        }
        data["filter"] = filter;

        this.mainpageservice.SavePrimeNgTable1(ID, this.Sys_Menu_ID, data, this.UserName).subscribe(resp => {
            let toast = this.toastController.create({
                message: resp["Message"],
                duration: 3000,
                position: 'bottom',
                //closeButtonText: 'Ok',

            });
            //this.GetPivotTableModule(ID, dt, filter, index);
            if (resp["Message"] == "Record saved successfully!!") {
                this.GetModulewithFilter(filter);
            }
            //this.GetModulewithFilter(filter);
            toast.then(toast => toast.present());
            this.show = false;
        });
    }

    //To submit Form
    onSubmit(form: any, ID: any, LinkedMenu: any, moduleIndex): void {
        let formObject: any = form;
        form = form.value;
        let filter1 = localStorage.getItem('navigationExtras');
        var moduledetails = this.Module[0].moduleList[moduleIndex].moduleDetails;
        Object.keys(form).forEach(key => {
            var v = moduledetails.filter(md => md.ColumnName == key);
            //if (v[0].DataType == "month") {
            //    if (form[key] != null) {
            //        let adate = new Date(form[key]);
            //        var ayear = adate.getFullYear();
            //        var amonth: any = adate.getMonth() + 1;
            //        if (amonth < 10) { amonth = '0' + amonth; }
            //        form[key] = amonth + "/" + ayear;
            //    }
            //}
            //else 
            if (v[0].DataType == "date") {
                if (form[key] != null) {
                    let adate = new Date(form[key]);
                    var ayear = adate.getFullYear();
                    var amonth: any = adate.getMonth() + 1;
                    var adt: any = adate.getDate();
                    if (adt < 10) { adt = '0' + adt; }
                    if (amonth < 10) { amonth = '0' + amonth; }
                    form[key] = ayear + '-' + amonth + '-' + adt;
                }
            }
            if (v[0].InputControls == "Checkbox") {
                if (form[key] == null || form[key] == "") {
                    form[key] == false;
                }
            }

        })
        this.routerEventSubscription = this.router.events.pipe(filter((event: RouterEvent) => event instanceof NavigationEnd)
        ).subscribe(() => {//refresh module on reload/navigation like back or submenu click
            this.GetModulewithFilter(filter1);
        });
        const that = this;
        let Mess = undefined;
        this.UserName = localStorage.getItem('username');
        this.show = true;

        Object.keys(form).map(function (key, index) {//with file upload
            if (Array.isArray(form[key]))// convert file object to file name string
            {
                if (form[key][0] instanceof File) {
                    var fileNames = "";

                    form[key].forEach(file => {
                        fileNames = file["name"] + "," + fileNames;
                    });
                    form[key] = fileNames.substring(0, fileNames.length - 1);
                }
            }
        });


        //Form Without FileUpload
        this.mainpageservice.Savedata(ID, form, this.UserName).subscribe(resp => {
            let toast = this.toastController.create({
                message: resp["Message"],
                duration: 3000,
                position: 'bottom',
                //closeButtonText: 'Ok',

            });
            if (LinkedMenu != 0 && LinkedMenu != '' && LinkedMenu != null) {
                this.navigateOnFormSubmit(LinkedMenu);
            }

            toast.then(toast => toast.present());
            this.show = false;
            if (resp["Message"] == "Record saved successfully!!") {
                this.GetModulewithFilter(filter1);

                let module = this.Module[0].moduleList.find(m => m.ID == ID)//if module is for create new User 
                if (module.ModuleName.toLowerCase() == "create new user")
                    this.createACSUserId(form.UserName);//assigns ACS userid to newly created user

                formObject.reset();
            }

        });


    }
    GetFileSelected(files: Array<File>) {
        debugger;
        this.FileList = files;

    }
    //For Saving The FeedBack of the ccustomers
    onSubmitFeedback(form: any): void {

        const that = this;

        let Mess = undefined;

        this.UserName = localStorage.getItem('username');

        this.show = true;

        //Form Without FileUpload

        this.mainpageservice.SaveFeedback(this.Sys_Menu_ID, form, this.UserName).subscribe(resp => {

            //console.log(JSON.stringify(resp["Message"]));

            let toast = this.toastController.create({

                message: resp["Message"],

                duration: 3000,

                position: 'bottom',

                //closeButtonText: 'Ok',

            });

            toast.then(toast => toast.present());

            this.show = false;

            this.mainpageservice.GetFeedback(this.Sys_Menu_ID, this.UserName, undefined).subscribe(data => {

                //console.log("Moduleapi", data);

                this.Feedback = data;

                this.show = false;



            });

        });

    }

    Savedatatable(form: any, ID: any): void {

        const that = this;
        let Mess = undefined;
        this.UserName = localStorage.getItem('username');
        this.show = true;
        let filter = localStorage.getItem('navigationExtras');

        //Form Without FileUpload
        this.mainpageservice.SaveDatatable(ID, form, this.UserName).subscribe(resp => {
            //console.log(JSON.stringify(resp["Message"]));
            let toast = this.toastController.create({
                message: resp["Message"],
                duration: 3000,
                position: 'bottom',
                //closeButtonText: 'Ok',

            });
            this.GetModulewithFilter(filter);
            toast.then(toast => toast.present());
            this.show = false;

        });

    }

    onSubmitFilter(formdata: any): void {

        this.displayedColumnArray = [];

        this.appliedFilters = formdata;

        Object.keys(formdata).forEach(key => {
            var v = this.PageMenuDetails.filter(md => md.ParameterName == key);
            if (v[0].DataType == "month") {
                if (formdata[key] != null) {
                    let adate = new Date(formdata[key]);
                    if (adate != undefined && adate.toString() != "Invalid Date") {
                        var ayear = adate.getFullYear();
                        var amonth: any = adate.getMonth() + 1;
                        if (amonth < 10) { amonth = '0' + amonth; }
                        formdata[key] = amonth + "/" + ayear;
                    }
                }
            }
            else if (v[0].DataType == "date") {
                if (formdata[key] != null) {
                    let adate = new Date(formdata[key]);
                    if (adate != undefined && adate.toString() != "Invalid Date") {
                        var ayear = adate.getFullYear();
                        var amonth: any = adate.getMonth() + 1;
                        var adt: any = adate.getDate();
                        if (adt < 10) { adt = '0' + adt; }
                        if (amonth < 10) { amonth = '0' + amonth; }
                        formdata[key] = ayear + '-' + amonth + '-' + adt;
                    }
                }
            }

        })

        console.log('you submitted value in Filter:', formdata);
        let data: any;
        data = JSON.stringify(formdata);
        //data = '{"Filter":' + data + '}';
        //console.log(data);
        this.UserName = localStorage.getItem('username');
        sessionStorage.setItem('filterdata', data);
        this.datatableparam = sessionStorage.getItem('datatableparameter');
        //console.log(this.datatableparam);
        this.GetModulewithFilter(formdata);
        this.showfilter = false;
        if (this.showfilter == false && this.horizontalFilter == false) {
            this.showfilter = true;
        }

    }
    downloadreport(): void {
        //alert(menuid);
        this.show = true;

        let data: any;
        this.UserName = localStorage.getItem('username');
        data = sessionStorage.getItem('filterdata');
        this.mainpageservice.exporttoexcel(this.Sys_Menu_ID, this.UserName, data).subscribe(resp => {
            //console.log(resp);

            //  application/vnd.openxmlformats-officedocument.spreadsheetml.sheet
            var blob = new Blob([resp], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

            var filename = 'download_report.xlsx';

            saveAs(blob, filename);

        })
        this.show = false;

    }

    downloadModuleReport(moduleId,moduleName) {
        debugger;
        this.show = true;

        let filters: any;
        let data: any = {};
        this.UserName = localStorage.getItem('username');
        let filter = localStorage.getItem("navigationExtras")
        let param = JSON.parse(filter);

        filters = sessionStorage.getItem('filterdata');
        if (filters == null) data.Filter = {};
        else {
            data["Filter"] = JSON.parse(filters);
        }
        if (filter != null && filter != undefined && filter != "")
            data.Filter["rowval"] = param["rowval"];
        debugger;

        this.mainpageservice.exporttoexcelModulewise(moduleId, this.UserName, JSON.stringify(data)).subscribe(resp => {
            //console.log(resp);

            //  application/vnd.openxmlformats-officedocument.spreadsheetml.sheet
            var blob = new Blob([resp], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

            var filename = moduleName + '.xlsx';

            saveAs(blob, filename);

        })
        this.show = false;
    }


    navigate(i, LinkedMenu) {

        let qm = { "rowval": i };

        let navigationExtras: NavigationExtras = {
            queryParams: {
                "rowval": i
            }

        };
        localStorage.setItem('navigationExtras', JSON.stringify(qm));
        this.events.publish('navigationExtras', qm);
        if(LinkedMenu!=0)this.router.navigateByUrl("/menu/first/tabs/" + LinkedMenu, navigationExtras);
        // this.router.navigate(["/menu/first/tabs/GotoForm"], navigationExtras);

    }

    ngAfterViewInit(): void {


        // this.slides.lockSwipes(true);
    }

    ngOnDestroy(): void {
        // Do not forget to unsubscribe the event
        this.routerEventSubscription.unsubscribe();
        this.events.destroy('navigationExtras');
    }

    ionViewDidLeave(): void {
        // Do not forget to unsubscribe the event
        this.routerEventSubscription.unsubscribe();
        this.events.destroy('navigationExtras');
    }
    onWizardSubmit(ID: any): void {
        debugger;
        this.UserName = localStorage.getItem('username');
        this.show = true;
        let form = JSON.stringify(this.WizardFormValue)
        //Form Without FileUpload
        this.mainpageservice.Savedata(ID, form, null).subscribe(resp => {
            //console.log(JSON.stringify(resp["Message"]));
            let toast = this.toastController.create({
                message: resp["Message"],
                duration: 3000,
                position: 'bottom',
                //closeButtonText: 'Ok',

            });
            toast.then(toast => toast.present());
            this.show = false;

        });

    }


    /////////////////Convert Image to blob////////////////////


    private base64textString: String = "";

    handleFileInput(files: any, columnName) {
        this.UserName = localStorage.getItem('username');
        for (let file of files) {
            this.mainpageservice.uploadFile(file, this.UserName, columnName).subscribe(resp => {
                this.show = false;
                //To display loader
                let toast = this.toastController.create({
                    message: resp["Message"],
                    duration: 3000,
                    position: 'bottom',
                    //closeButtonText: 'Ok'
                });
                toast.then(toast => toast.present());
            });

        }
        this.show = true;

    }

    _handleReaderLoaded(readerEvt) {
        var binaryString = readerEvt.target.result;
        this.id = Math.random();
        sessionStorage.setItem('id', this.id);
        this.base64textString = btoa(binaryString);
        //console.log(btoa(binaryString));
        this.storage.set('sampler-' + this.id, btoa(binaryString));
        let FileType: FileObject = {
            name: this.FileName,
            FileData: this.b64toBlob(btoa(binaryString), '', '')
        };
        //console.log(btoa(binaryString));

        this.storage.set('FileType-' + this.id, FileType);
        let toast = this.toastController.create({
            message: "Your image is stored. Click on Submit to save the form.",
            duration: 3000,
            position: 'bottom',
            //showCloseButton: true,
            //closeButtonText: 'Ok',

        });
        toast.then(toast => toast.present());


        //console.log('blob data while passing to savedata1' + JSON.stringify(FileType));


    }

    replacer(key, val) {
        if (key == "_parent" || key == "parent") return undefined;
        else return val;

    }

    b64toBlob(b64Data, contentType, sliceSize) {
        contentType = contentType || '';
        sliceSize = sliceSize || 512;

        var byteCharacters = atob(b64Data);
        var byteArrays = [];

        for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
            var slice = byteCharacters.slice(offset, offset + sliceSize);

            var byteNumbers = new Array(slice.length);
            for (var i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
            }

            var byteArray = new Uint8Array(byteNumbers);

            byteArrays.push(byteArray);
        }

        var blob = new Blob(byteArrays, { type: contentType });
        return blob;
    }

    doAsyncGenerateReport(Rowval, ModuleId, ModuleDetailId, ReportType) {
        let promise = new Promise((resolve, reject) => {
            setTimeout(() => {
                let data: any
                this.UserName = localStorage.getItem('username');
                data = '{Rowval:"' + Rowval + '",' + 'ModuleDetailId:"' + ModuleDetailId + '",ModuleId:"' + ModuleId + '"}';


                this.mainpageservice.GenerateReport(ReportType, this.UserName, JSON.stringify(data)).subscribe(resp => {

                    //  application/vnd.openxmlformats-officedocument.spreadsheetml.sheet
                    var blob = new Blob([resp], { type: 'application/pdf' });
                    var d = new Date();
                    var montharr = d.getMonth() + 1;
                    var month = (montharr < 10 ? '0' : '') + montharr;
                    var day = ((d.getDate()) < 10 ? '0' : '') + (d.getDate());
                    var year = d.getFullYear();
                    var x = year + '-' + month + '-' + day;
                    var filename = ReportType + x + '.pdf';

                    saveAs(blob, filename);
                    this.show = false;
                });
                resolve();
            }, 1000);
        });

        debugger;
        return promise;
    }

    GenerateReport(Rowval, ModuleId, ModuleDetailId, ReportType) {
        if (Rowval == '' || typeof (Rowval) === undefined || Rowval == null) {
            let filter = localStorage.getItem("navigationExtras");
            let param = JSON.parse(filter);
            Rowval = param["rowval"];
        }


        this.doAsyncGenerateReport(Rowval, ModuleId, ModuleDetailId, ReportType).then(
            () => this.show = true,
            // () => //console.log("Task Errored!"),
        );

    }
    SendReport(Rowval, ModuleId, ModuleDetailId, ReportType) {
        let data: any
        this.UserName = localStorage.getItem('username');
        data = '{Rowval:"' + Rowval + '",' + 'ModuleDetailId:"' + ModuleDetailId + '",ModuleId:"' + ModuleId + '"}';


        this.mainpageservice.SendReport(ReportType, this.UserName, JSON.stringify(data)).subscribe(resp => {

            let toast = this.toastController.create({
                message: "Mail sent successfully!",
                duration: 3000,
                position: 'bottom',
                //closeButtonText: 'Ok',

            });
            toast.then(toast => toast.present());
            this.show = false;

        })
    }
    toggleOTPEmail(email: any) {
        this.VerifiedEmailId = email;
        this.showContainerEmail = true;
        this.mainpageservice.GenerateOTPEmail(email).subscribe(resp => {
            alert(JSON.stringify(resp));
            let OTP = resp["OTP"];
            localStorage.setItem('OTP', OTP);

        });
    }
    CheckOTP(OTP: any) {
        let OTPValue = localStorage.getItem('OTP');
        if (OTP == OTPValue) {
            alert("Email validated");
            localStorage.removeItem('OTP');
            this.showContainerEmail = false;
            this.ValidatedEmail = true;
        }
        else {
            alert("OTP not valid");
        }
    }

    setStep(index: number) {
        this.step = index;
    }

    nextStep() {
        this.step++;
    }

    prevStep() {
        this.step--;
    }

    multipleDependantDropdown(moduleDetailId, value, dependantSP) {
        var dd = this.Module[0].moduleList[0].submodule[0].subModuleDetails.filter(md => md.DependentOn == moduleDetailId);
        console.log(dd);
        this.mainpageservice.MultipleDependentDropdown(moduleDetailId, value, dependantSP, this.UserName).subscribe(resp => {
            console.log(resp);
            var i = 0;
            dd.forEach(md => {
                var index = "";
                var dependentDropdown = this.Module[0].moduleList[0].submodule[0].subModuleDetails.find(smd => smd.ID == md.ID);
                if (i != 0)
                    index = "" + i;
                dependentDropdown.DropDownValues = resp["Table" + index];
                i++;
            });
        })
    }


    autoPopulateMultipleFields(moduleDetailId, filterByValue, auotoPopulateSP, subModuleIndex) {
        //For FormTableSubmodule only
        var dd = this.Module[0].moduleList[0].submodule[subModuleIndex].subModuleDetails.filter(md => md.DependentOn == moduleDetailId);
        if (this.Module[0].moduleList[0].submodule[subModuleIndex].DisplayType == "Form") {
            var tableSubmoduleIndex = this.Module[0].moduleList[0].submodule.findIndex(sm => sm.DisplayType == "PrimeUnfrozenTable");
            var tableDropdown = this.Module[0].moduleList[0].submodule[tableSubmoduleIndex].subModuleDetails.filter(md => md.InputControls.toLowerCase() == "dropdownlist")
            tableDropdown.forEach(element => {
                this.GetValuesForDependentDropdown(element.ID, filterByValue);//Form to table dependent dropdown
            });
        }
        this.mainpageservice.AutoPopulateMultipleFields(filterByValue.Text, auotoPopulateSP, this.UserName).subscribe(resp => {
            console.log(resp);
            var value = "";
            dd.forEach(md => {
                var fieldToBePopulated = this.Module[0].moduleList[0].submodule[subModuleIndex].subModuleDetails.find(smd => smd.ID == md.ID);
                if (fieldToBePopulated.InputControls.toLowerCase() == "dropdownlist")
                    value = fieldToBePopulated.DropDownValues.find(o => o.Text == resp[0][md.ColumnName]);
                else ///other input controls
                    value = resp[0][md.ColumnName];

                if (this.Module[0].moduleList[0].submodule[subModuleIndex].DisplayType != "Form")
                    this.thisrow[md.ColumnName] = value;//for dialog box fields
                else
                    this.Module[0].moduleList[0].submodule[subModuleIndex].subModuleDetails.find(f => f.ColumnName == md.ColumnName).value = value;//for Form fields

                this.disable = true;//to disable auto populated fields
            });
        })
    }


    setImportPanel(importPanel) {//To show file upload modulewise on Upload button click
        importPanel.hidden = !importPanel.hidden;
    }

    Run(i) {
        let qm = { "rowval": i };
        this.UserName = localStorage.getItem('username');
        this.show = true;

        this.mainpageservice.RunWorkflowTask(i, this.UserName, undefined).subscribe(resp => {

            console.log(JSON.stringify(resp["Message"]));
            //To display loader
            let toast = this.toastController.create({
                message: resp["Message"],
                duration: 3000,
                position: 'bottom',
                //closeButtonText: 'Ok',

            });
            toast.then(toast => toast.present());
            this.show = false;
        });

    }

    GetValuesForDependentDropdown(DDModuleDetailID, filterByValue) {
        var dd = this.Module[0].moduleList[0].submodule[1].subModuleDetails.filter(md => md.ID == DDModuleDetailID);
        console.log(dd);
        this.mainpageservice.MultipleDependentDropdown(DDModuleDetailID, filterByValue.Text, dd[0].DropDownSP, this.UserName).subscribe(resp => {
            console.log(resp);
            var i = 0;
            dd.forEach(md => {
                var index = "";
                var dependentDropdown = this.Module[0].moduleList[0].submodule[1].subModuleDetails.find(smd => smd.ID == md.ID);
                if (i != 0)
                    index = "" + i;
                dependentDropdown.DropDownValues = resp["Table"];
                i++;
            });
        })
    }


    SubmitTreeTable(ID: any) {
        this.show = true;
        var filter1 = localStorage.getItem('navigationExtras');
        var updateData = this.files.filter(element => element.data.Updated == true)
        console.log(updateData);
        var dataObject = JSON.stringify(updateData, this.replacer);
        let treetableModuleIndex: number;
        var treetableModule = this.Module[0].moduleList.find(m => m.DisplayType.toLowerCase() == "treetable");
        treetableModuleIndex = this.Module[0].moduleList.indexOf(treetableModule);

        this.mainpageservice.SaveTreetable(ID, dataObject, this.UserName, treetableModuleIndex).subscribe(resp => {
            let toast = this.toastController.create({
                message: resp["Message"],
                duration: 3000,
                position: 'bottom',
                //closeButtonText: 'Ok',

            });
            toast.then(toast => toast.present());
            this.show = false;
            updateData.forEach((node) => {
                node.data.Updated = false;
            });
        });
    }

    treetable(filter) {
        let treeNodes: Array<FileSystemNode> = [];
        treeNodes = filter.serializedValue;
        var filteredNodes = treeNodes.filter(x => x.parent != null)

        filteredNodes.forEach(treenode => {

            let node = new FileSystemNode(null, null, this.scrollableCols, treenode);

        });
    }

    /////RowGroup highlight
    updateRowGroupMetaData(data,groupByColumnName, sortColumnName) {
        //alert();
        this.rowGroupMetadata = {};
        if (data) {
            let groupIndex = 1;
            for (let i = 0; i < data.length; i++) {
                let rowData = data[i];
                let sortColumnValue = rowData[sortColumnName];
                let groupbyColumnValue = rowData[groupByColumnName];
                //this.rowGroupMetadata[sortColumnValue]={};
                if (i == 0) {
                    this.rowGroupMetadata[groupbyColumnValue] = {};
                    this.rowGroupMetadata[groupbyColumnValue][sortColumnValue] = { index: 0, size: 1, GroupIndex: groupIndex };
                }
                else {
                    let previousRowData = data[i - 1];
                    let previousRowGroup = previousRowData[groupByColumnName];
                    if (groupbyColumnValue === previousRowGroup) {
                        if (rowData[sortColumnName] == previousRowData[sortColumnName]) {
                            this.rowGroupMetadata[groupbyColumnValue][sortColumnValue].size++;
                        }
                        else {
                            groupIndex++;
                            this.rowGroupMetadata[groupbyColumnValue][sortColumnValue] = { index: i, size: 1, GroupIndex: groupIndex };
                        }
                    }
                    else {
                        groupIndex++;
                        this.rowGroupMetadata[groupbyColumnValue] = {};
                        this.rowGroupMetadata[groupbyColumnValue][sortColumnValue] = { index: i, size: 1, GroupIndex: groupIndex };
                        //
                    }
                }
                // 
            }
            // console.log(this.rowGroupMetadata);
        }

    }

    isEven(groupIndex) {
        if (groupIndex % 2 == 0)
            return { "background-color": "#F4F4F4", };
        else
            return { "background-color": "white" };
    }

    onSort(data, groupByColumnName, sortColumnName) {
        this.updateRowGroupMetaData(data, groupByColumnName, sortColumnName);
    }

    async createACSUserId(username) {
        var connectionString = "endpoint=https://chatapptest.communication.azure.com/;accesskey=05tg2gMhjPpht5w+x9KftqZpLiRCFiiyrJUdTvOdWlzLZs23B9OHHafoqzEPQrfxqKVUa+4TJ4MV+AcB24pfRA==";
        var userId = await this.ACSUser.createUser(connectionString);
        this.ACS.assignACSUserID(username, userId).subscribe(resp => {
            console.log("assignACSUserID", resp);
            let toast = this.toastController.create({
                message: resp.toString(),
                duration: 3000,
                position: 'bottom',
                //closeButtonText: 'Ok',

            });
            toast.then(toast => toast.present());
        })
    }
}
