// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    production: false,
    // apiUrl: 'http://localhost:52371/',
    // apiUrl: 'https://hafeledapwebapi.azurewebsites.net/',
    //apiUrl: 'http://newinternaltestingwebapi.datasavi.in/',
     
    apiUrl: 'http://hafeledapwebapi.datasavi.in/',        // test link
    //apiUrl: 'https://hafeledapwebapi.datasavi.net/',     //new webapi azurevm link

    //apiUrl: 'https://hafeledapwapi.azurewebsites.net/',   // New IMBS production link
    AppName:'HAFELE',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
